<template>
  <section class="dvla-mini-form container-fluid">
    <div class="row">
      <div class="col-12 col-md form my-2 p-2" style="max-width: 580px">
        <div class="container-icon-shield w-100 text-center">
          <div class="icon px-2">
            <img :src="require('@/assets/images/icons/blue-shield.png')" width="60px" />
          </div>
        </div>

        <div
          class="d-flex align-items-center"
          :style="{ 'margin-bottom': step == 0 ? '35px' : '' }"
        >
          <i
            v-if="step !== 0"
            @click="!sending && back()"
            class="back-button ml-2 fas fa-angle-left"
          ></i>
        </div>

        <!-- Steps -->
        <!-- <div class="steps row px-0 px-md-3 mt-3 mb-2">
          <div
            v-for="(item, index) in questions"
            :key="index"
            :class="`col step ${
              index <= step ? 'step-active-2 animate__animated animate__bounceIn' : ''
            }`"
            @click="setGroupQuestions(index)"
          ></div>
        </div> -->

        <div class="d-flex justify-content-center">
          <div class="text-center" style="width: 90%">
            <h2 class="mb-1">DVLA change of address</h2>
            <p class="mb-3">
              To process your change of address we just need to know a little bit more information
            </p>
            <div class="questions" v-for="(item, index) in this.currentQuestions" :key="index">
              <!-- Select -->
              <div class="mb-3" v-if="item.typeInput == 'select'">
                <p v-if="item.headerQuestion" class="text-left text-white">
                  {{ item.headerQuestion }}
                </p>
                <p class="text-left text-white">{{ item.title }}</p>
                <vSelect
                  :value="item.value"
                  class="w-100 select"
                  :label="item.title"
                  :clearable="false"
                  :options="item.options"
                  :placeholder="item.title"
                  :multiple="false"
                  @input="(e) => updateValue(e, index, item.valName)"
                  ><!-- :dropdown-should-open="() => true"   -->
                </vSelect>
                <p
                  class="mt-1 mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                  v-if="item.displayError"
                >
                  * {{ item["messageError"] }}
                </p>
              </div>
              <!-- Input Text -->
              <div class="mb-3" v-if="item.typeInput == 'text'">
                <p v-if="item.headerQuestion" class="text-left text-white">
                  {{ item.headerQuestion }}
                </p>

                <p class="text-left text-white mb-1">
                  {{ item.title }}
                  <i
                    v-if="item['tooltipImage']"
                    class="ml-1 fa-solid fa-circle-info"
                    style="color: rgb(35, 169, 215); font-size: 18px; cursor: pointer"
                    @mouseover="toggleTooltip(`tooltip-${index}`)"
                    @mouseleave="toggleTooltip(`tooltip-${index}`)"
                  ></i>
                  <img
                    v-if="item['tooltipImage']"
                    :id="`tooltip-${index}`"
                    :src="item.tooltipImage"
                    class="tooltip-img"
                    width="350px"
                    height="210px"
                  />
                </p>
                <input
                  type="text"
                  :placeholder="item['placeholder'] ? item['placeholder'] : item.title"
                  class="w-100 p-1"
                  :value="item.value"
                  @input="(e) => updateValue(e.target.value, index, item.valName)"
                />
                <p
                  class="mt-1 mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                  v-if="item.displayError"
                >
                  * {{ item["messageError"] }}
                </p>
              </div>

              <div class="mb-3" v-if="item.typeInput == 'IntroText'">
                <p style="font-weight: 600" class="text-left text-white">Your Driving Licence Update</p>
                <p class="text-left text-white"> To process your DVLA address change, we need to
                  confirm your name, address, and date of birth to
                  ensure accuracy and compliance.</p>
                <p class="text-left text-white"> <b>Quick and Easy:</b> You can upload a photo of the front
                of your driving licence for faster, automatic data
                entry.</p>
                <p class="text-left text-white">
                  <b>Prefer Manual Entry?</b> No problem—you’ll have the
                  option to enter your details manually if you’d prefer.
                </p>
              </div>
              <!-- Select Boxes Questions -->
              <div class="mb-3" v-if="item.typeInput == 'select-box'">
                <p class="text-left text-white">{{ item.title }}</p>
                <div class="d-flex">
                  <div
                    class="box-answers"
                    :class="{ checked: item.value == option }"
                    v-for="(option, i) in item.options"
                    :key="i"
                    @click="(e) => updateValue(option, index, item.valName)"
                  >
                    {{ option }}
                  </div>
                </div>
                <p
                  class="mt-1 mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                  v-if="item.displayError"
                >
                  * {{ item["messageError"] }}
                </p>
                <p
                  class="mt-1 mb-0 text-left dvla-question-warning"
                  v-if="item.warningMessage && item.warningMessage()"
                >
                  * {{ item.warningMessage() }}
                </p>
              </div>

              <div  class="mb-3 question" v-if="item.valName == 'licenseFront'">
                <ImgUploadFront
                  @extractedText="(value) => updateValueExtractedText(value)">
                </ImgUploadFront>
                <p
                    class="mt-1 mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                    v-if="item.displayError"
                >
                  * {{ item["messageError"] }}
                </p>
              </div>
              <div  class="mb-3 question" v-if="item.valName == 'licenseBack'">
                <ImgUploadBack
                    @extractedText="(value) => updateValueExtractedText(value)">
                </ImgUploadBack>

                <p
                    class="mt-1 mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                    v-if="item.displayError"
                >
                  * {{ item["messageError"] }}
                </p>
              </div>
              <div class="mb-3 question" v-if="item.typeInput == 'date'">
                <p class="text-left text-white">{{ item.title }}</p>
                <!--< Datepicker
                    :value="item.value"
                    :highlighted="{ dates: [new Date(Date.now())] }"
                    @selected="(e) => updateValue(e, index, item.valName)"
                    id="datepicker-1"
                    class="w-100"
                    :disabled-dates="disabledDates()"
                  /> -->

                <DateMultiSelect
                  :dateModel="item.value"
                  @formattedDate="(value) => updateValue(value, index, item.valName)"
                  :isDOB="false"
                  ref="dateMultiSelect"
                />

                <p
                  class="mt-1 mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                  v-if="item.displayError"
                >
                  * {{ item["messageError"] }}
                </p>
              </div>

              <!-- Vehicle Registration -->

              <div class="vehicle-registration" v-if="item.typeInput == 'vehicles-registration'">
                <p class="text-left text-white">{{ item.title }}</p>

                <div
                  class="mb-3"
                  v-for="(vehicle, i) in dvlaQuestions['vehiclesRegistered']"
                  :key="i"
                >
                  <p
                    v-if="messageVehicleRegistration('input-vehicle-registration-' + i)"
                    class="text-left"
                    style="color: rgb(198, 74, 69)"
                  >
                    Please include any spaces as they appear on your number plate i.e. AB10 CDE
                  </p>
                  <div class="d-flex mb-2">
                    <div class="text-center flag-box">
                      <img :src="require('@/assets/flag-uk.png')" width="25" alt="flag-uk" />
                      <h5 class="mb-0" style="font-weight: 800">GB</h5>
                    </div>

                    <div style="width: 65%">
                      <input
                        type="text"
                        :data-target="'input-vehicle-registration-' + i"
                        placeholder=""
                        class="mb-1 w-100 input-vehicle-registration"
                        :value="vehicle.carRegistration"
                        @input="(e) => vehicleRegistration(e.target.value.toUpperCase(), i)"
                        @keyup="(e) => ifEmptyDeleteVehicle(e, i)"
                      />
                      <p
                        v-if="i === 0"
                        class="text-right text-decoration-underline mb-0 add"
                        @click="addVehicle()"
                        style="font-size: 13px"
                      >
                        Add a another vehicle
                      </p>
                    </div>

                    <div
                      class="d-flex align-items-center"
                      style="height: 58px"
                      v-if="validateVehicleRegistration(vehicle.carRegistration)"
                    >
                      <img
                        class="ml-2"
                        :src="require('@/assets/circle-check.png')"
                        alt="circle-check"
                      />
                    </div>
                  </div>
                  <p
                    class="mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                    v-if="item.displayError"
                  >
                    * {{ item["messageError"] }}
                  </p>
                  <p
                    class="mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                    v-else-if="vehicle.hasOwnProperty('notFoundIt') ? vehicle.notFoundIt : false"
                  >
                    * Registration number not found
                  </p>

                  <!-- V5C Number -->
                  <div class="input-v5c-number">
                    <div class="d-flex justify-content-between">
                      <p style="width: 90%" class="text-left text-white mb-0">
                        What is your V5C reference number?
                        <i
                          class="ml-1 fa-solid fa-circle-info"
                          style="color: rgb(35, 169, 215); font-size: 18px; cursor: pointer"
                          @mouseover="toggleTooltip(`tooltip-${i}`)"
                          @mouseleave="toggleTooltip(`tooltip-${i}`)"
                        ></i>
                        <img
                          :id="`tooltip-${i}`"
                          :src="`${urlBucket}/form/images/v5cDocument.png`"
                          class="tooltip-img"
                          width="350px"
                          height="210px"
                        />
                      </p>
                    </div>

                    <input
                      type="number"
                      placeholder=""
                      class="w-100 p-1"
                      :value="vehicle.v5cNumber"
                      @input="(e) => updateV5CNumber(e.target.value, vehicle, i)"
                    />
                    <p
                      class="mt-1 mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                      v-if="
                        (validateV5CNumber(vehicle.v5cNumber) && vehicle.v5cNumber.length > 0) ||
                        (vehicle.v5cNumber.length == '' && error.v5cNumber)
                      "
                      style="color: #c64a45"
                    >
                      * The V5C reference number is 11 digits and can be found on your V5c document.
                      Hover over the 'i' icon to know where to find it
                    </p>
                  </div>

                  <div class="card-vehicle p-1 my-1" v-if="Object.keys(vehicle.info).length > 0">
                    <h5 style="color: #22a8d6" class="">{{ vehicle.carRegistration }}</h5>
                    <p class="mb-0">
                      <span class="fw-bold">Make:</span> {{ vehicle.info["make"] }}
                    </p>
                    <p class="mb-0">
                      <span class="fw-bold">Year of Manufacture:</span>
                      {{ vehicle.info["yearOfManufacture"] }}
                    </p>
                    <p class="mb-0">
                      <span class="fw-bold">CO2 Emissions:</span> {{ vehicle.info["co2Emissions"] }}
                    </p>
                    <p class="mb-0">
                      <span class="fw-bold">Colour:</span> {{ vehicle.info["colour"] }}
                    </p>
                    <p class="mb-0">
                      <span class="fw-bold">Engine Capacity:</span>
                      {{ vehicle.info["engineCapacity"] }}
                    </p>
                    <p class="mb-0">
                      <span class="fw-bold">Fuel Type:</span> {{ vehicle.info["fuelType"] }}
                    </p>
                    <p class="mb-0">
                      <span class="fw-bold">Type Approval:</span> {{ vehicle.info["typeApproval"] }}
                    </p>
                    <p class="mb-0">
                      <span class="fw-bold">Wheel Plan:</span> {{ vehicle.info["wheelplan"] }}
                    </p>
                  </div>
                  <div v-if="vehicle.img.length > 0">
                    <img
                      :src="vehicle.img"
                      class="img-fluid"
                      alt="car-image"
                      style="border-radius: 50px; display: none"
                      @load="(e) => (e.target.style.display = 'block')"
                    />
                  </div>
                  <p
                    v-if="
                      Object.keys(vehicle.info).length > 0 &&
                      dvlaQuestions['vehiclesRegistered'].length > 1
                    "
                    class="text-right mt-1 text-decoration-underline mb-0 remove"
                    @click="deleteVehicle(i)"
                    style="font-size: 13px"
                  >
                    Remove vehicle
                  </p>
                </div>
              </div>

              <!-- Check Box -->
              <div v-if="item.typeInput == 'checkbox'">
                <div class="d-flex mb-2">
                  <div class="d-flex align-items-center">
                    <p-check
                      name="check"
                      class="pretty p-icon p-curve p-thick p-jelly"
                      style="font-size: 22px"
                      color="primary"
                      @change="(e) => updateValue(e, index, item.valName)"
                      v-model="item.value"
                    >
                      <div class="state">
                        <i style="margin-left: -67%" class="icon mdi mdi-check"></i>
                      </div>
                    </p-check>
                  </div>

                  <p class="mb-0">
                    {{ item.title }}
                  </p>
                </div>
                <p
                  class="mt-1 mb-0 text-left dvla-question-error animate__animated animate__shakeX"
                  v-if="item.displayError"
                >
                  * {{ item["messageError"] }}
                </p>
              </div>
            </div>
            <button
              role="button"
              id="mainButton"
              class="button-sloth mt-1 d-flex justify-content-center"
              @click="onClickButtonForm()"
              :disabled="sending"
            >
              <h4 class="mb-0 font-weight-bold">{{ messageButton }}</h4>
              <div v-if="sending" class="ml-1 spinner-border text-light" role="status">
                <span class="sr-only"></span>
              </div>
            </button>

            <!-- <p
              class="text-center mt-2"
              style="text-decoration: underline; cursor: pointer"
              @click="!sending && provideLater()"
            >
              PROVIDE LATER
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axiosCustom from "@axios";
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { countries } from "../lists";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import PrettyCheckbox from "pretty-checkbox-vue";
import DateMultiSelect from "./DateMultiSelect.vue";
import ImgUploadFront from "./ImgUploadFront.vue";
import ImgUploadBack from "./ImgUploadBack.vue";
import Vue from "vue";

Vue.use(PrettyCheckbox);

export default {
  name: "DVLAMiniForm",
  components: {
    vSelect,
    Datepicker,
    DateMultiSelect,
    ImgUploadFront,
    ImgUploadBack,
  },
  data() {
    return {
      sending: false,
      step: 0,
      questions: [],
      currentQuestions: [],
      messageButton: "CONTINUE",
      frontUploadSuccess: false,
      error: {
        v5cNumber: false,
      },
      dvlaExtractedText: {
        title:"",
        firstName:"",
        middleName:"",
        lastName:"",
        renewalDate:"",
        expired:false,
        driversLicenceNumber:"",
        driversLicenceIssueNumber:"",
        postcode:"",
        address:"",
        dateOfBirth:"",
        countryOfBirth:"",
        photoId:"",
        provisionalLicence:false
      },
      //DVLA-questions
      dvlaQuestions: {
        dvlaNeed: "",
        /* License */
        driving: "",
        driveLicenseNumberDVLALetter: "",
        license: "",
        licenseFile1: {},
        licenseFile2: {},
        paperlicense: "",
        renewLicense: "",
        lostLicense: "",
        provisionalLicense: "",
        surnameAtBirth: "",
        motherMaidenName: "",
        previousAddressPrinted: "",
        datePreviousAddress: "",
        addressPrintedLicense: "",
        postCodePrinted: "",
        whenMoveIntoAddress: "",
        whereLiveBeforeAddress: "",
        whereLiveBeforePostcode: "",
        whenMoveIntoAddress2: "",
        titleLicense: "",
        firstNameLicense: "",
        middleNameLicense: "",
        surnameLicense: "",
        countryBirth: "",
        photocardNumber: "",
        driversLicenseNumber: "",
        driversLicenseIssueNumber: "",
        agreeCutOldlicense: false,
        /* Vehicle Registration  */
        registeredKeeper: "",
        lostV5CDocument: "",
        vehiclesRegistered: [],
        // carRegistration: "",
        v5cNumber: "",
        postCodePrintedV5C: "",
        postCodeVehicleRegistered: "",
        agreeDestroyOldV5C: false,
      },
    };
  },
  mounted() {
    console.log({ currentMove: this.currentMove });

    for (const [key, value] of Object.entries(this.currentMove.dvlaQuestions)) {
      // console.log(`${key}: ${value}`);
      this.dvlaQuestions[key] = value;
    }

    this.dvlaQuestions["vehiclesRegistered"] =
      this.currentMove.dvlaQuestions.vehiclesRegistered.map((item) => {
        if (!item.hasOwnProperty("v5cNumber")) {
          item["v5cNumber"] = "";
        }

        return item;
      });

    this.dvlaQuestions["registeredKeeper"] = "Yes";

    this.displayQuestions();
  },
  methods: {
    displayQuestions() {
      let q = this.dvlaQuestions;

      const {
        moveFromAddress,
        moveFromPostCode,
        moveInDate,
        moveOutDate,
        moveOutFlag,
        moveToAddress,
        completeMoveFromAddress,
        completeMoveToAddress,
      } = this.currentMove["moveDetails"];

        //let { dvlaNeed } = this.currentMove["dvlaQuestions"];

      if (q.dvlaNeed == "license") {
        this.dvlaQuestions.dvlaNeed = "My licence";
      }

      if (q.dvlaNeed == "vehicle") {
        this.dvlaQuestions.dvlaNeed = "My vehicle registration (V5C)";
      }

      if (q.dvlaNeed == "both") {
        this.dvlaQuestions.dvlaNeed = "Both";
      }
      this.questions = [];

      if (q.dvlaNeed == "") {
        this.questions = [
          // First Question
          [
            {
              title: "What do you need to update?",
              value: q.dvlaNeed,
              valName: "dvlaNeed",
              typeInput: "select",
              options: ["My licence", "My vehicle registration (V5C)", "Both"],
              validations: function () {
                return this.value == "";
              },
              messageError: "This field is required",
              displayError: false,
            },
          ],
        ];
      }

      let licenseQuestions = [
        /* License */
        {
          title: "",
          value:  null,
          valName: "",
          typeInput: "IntroText",
          options: null,
          tooltipImage: `${this.urlBucket}/form/images/drivingLicense.png`,
          validations: function () {
            return false;
          },
          messageError: "",
          displayError: false,
          showIf: () => q.driving == "No" &&  q.lostLicense == "No",
        },
        {
          title: "Are you currently disqualified from driving?",
          value: q.driving,
          valName: "driving",
          typeInput: "select-box",
          options: ["Yes", "No"],
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please select if you are currently disqualified from driving.",
          displayError: false,
          showIf: () => true,
          finishFlow: function () {
            return this.value == "Yes" && q.dvlaNeed != "Both";
          },
          warningMessage: function () {
            return (
              this.value == "Yes" &&
              "Great, we can still help you to update your licence. We just need a little bit of information in order to inform the DVLA"
            );
          },
        },

        {
          title: "What is your driving licence number?",
          value: q.driveLicenseNumberDVLALetter?.toLocaleUpperCase() ?? null,
          valName: "driveLicenseNumberDVLALetter",
          typeInput: "text",
          options: null,
          tooltipImage: `${this.urlBucket}/form/images/drivingLicense.png`,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "This field is required",
          displayError: false,
          showIf: () => q.driving == "Yes",
        },

        {
          title: "Have you lost your licence?",
          value: q.lostLicense,
          valName: "lostLicense",
          typeInput: "select-box",
          options: ["Yes", "No"],
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please select if you have lost your licence",
          displayError: false,
          showIf: () => q.driving == "No" ,
          finishFlow: function () {
            return this.value == "Yes" && q.dvlaNeed != "Both";
          },
          warningMessage: function () {
            return (
              this.value == "Yes" &&
              "We need the information on your licence in order to complete your licence address update, therefore if you have lost your licence you will need to first apply for a new licence in order to update your address"
            );
          },
        },
        {
          title: "Do you have a photocard or paper licence?",
          value: q.license,
          valName: "license",
          typeInput: "select",
          options: ["photocard", "paper licence"],
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please select if your licence is a photocard or paper.",
          displayError: false,
          showIf: () => q.driving == "No" &&  q.lostLicense == "No",
        },

        {
          title: null,
          subTitle: null,
          value: q.licenseFile1,
          valName: "licenseFront",
          typeInput: "file-upload",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please upload a picture of your photocard",
          displayError: false,
          showIf: () =>  q.driving == "No" &&
              q.lostLicense === "No" &&
              q.license === "photocard",
        },
        {
          title: null,
          subTitle: null,
          value: q.licenseFile2,
          valName: "licenseBack",
          typeInput: "file-upload",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please upload a picture of your photocard",
          displayError: false,
          showIf: () =>  q.driving == "No" &&
              q.lostLicense === "No" &&
              q.license === "photocard"
        },
        {
          title: "Has your driving licence expired?",
          value: q.renewLicense,
          valName: "renewLicense",
          typeInput: "select-box",
          options: ["Yes", "No"],
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please select if you need to renew your licence",
          displayError: false,
          showIf: () => q.driving == "No",
          finishFlow: function () {
            return this.value == "Yes" && q.dvlaNeed != "Both";
          },
          warningMessage: function () {
            return (
                this.value == "Yes" &&
                "You will need to renew your licence. As you're renewing your licence you will be able to update your address too"
            );
          },
        },
        {
          title: "Do you have a provisional licence?",
          value: q.provisionalLicense,
          valName: "provisionalLicense",
          typeInput: "select-box",
          options: ["Yes", "No"],
          validations: function () {
            return false;
          },
          messageError: "",
          displayError: false,
          showIf: () => q.driving == "No" && q.renewLicense == "No" && q.lostLicense == "No",
        },
        {
          title: `Is ${moveFromAddress} the address printed on your licence?`,
          value: q.previousAddressPrinted,
          valName: "previousAddressPrinted",
          typeInput: "select-box",
          options: ["Yes", "No"],
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please select an option",
          displayError: false,
          showIf: () => q.driving == "No" && q.renewLicense == "No" && q.lostLicense === "No",
        },
        /* {
          title: `What date did you move into ${moveFromAddress}?`,
          value: q.datePreviousAddress,
          valName: "datePreviousAddress",
          typeInput: "date",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "This field is required",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            q.previousAddressPrinted == "Yes",
        }, */
        {
          // headerQuestion: "What address is printed on your license?",
          title: "What address is printed on your licence?",
          value: q.addressPrintedLicense,
          valName: "addressPrintedLicense",
          placeholder: this.currentMove.moveDetails.completeMoveToAddress?.line_1,
          typeInput: "text",
          options: null,
          tooltipImage: `${this.urlBucket}/form/images/addres-sprinted-license.png`,
          validations: function () {
            return (q.previousAddressPrinted == "No" && this.value == "") || this.value == null;
          },
          messageError: "This field is required",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            q.previousAddressPrinted == "No",
        },
        {
          title: "Postcode as printed on your licence",
          value: q.postCodePrinted,
          valName: "postCodePrinted",
          typeInput: "text",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "This fields is required",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            q.previousAddressPrinted == "No",
        },
        /* {
          title: `When did you move into ${q.addressPrintedLicense}?`,
          value: q.whenMoveIntoAddress,
          valName: "whenMoveIntoAddress",
          typeInput: "date",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "This field is required",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            q.previousAddressPrinted == "No",
        }, */
        {
          /* isMoveInDateMoreThan3Years() */
          title: `Where did you live before ${moveFromAddress}?`,
          value: q.whereLiveBeforeAddress,
          valName: "whereLiveBeforeAddress",
          typeInput: "text",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "This field is required",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            !this.isMoveInDateMoreThan3Years(),
        },
        {
          /* isMoveInDateMoreThan3Years() */ title: `Postcode for ${q.whereLiveBeforeAddress}`,
          value: q.whereLiveBeforePostcode,
          valName: "whereLiveBeforePostcode",
          typeInput: "text",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "This field is required",
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            !this.isMoveInDateMoreThan3Years(),
        },
        {
          /* isMoveInDateMoreThan3Years() */
          title: `When did you move into ${q.whereLiveBeforeAddress}?`,
          value: q.whenMoveIntoAddress2,
          valName: "whenMoveIntoAddress2",
          typeInput: "date",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "This field is required",
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            !this.isMoveInDateMoreThan3Years(),
        },
        /* How is your name printed on your license?  */
        {
          headerQuestion: "How is your name printed on your licence?",
          title: "Select a title",
          value: q.titleLicense,
          valName: "titleLicense",
          typeInput: "select",
          options: [
            "Mr",
            "Ms",
            "Mrs",
            "Miss",
            "Dr",
            "Professor",
            "Lady",
            "Dame",
            "Sir",
            "Judge",
            "Lord",
          ],
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please enter your title",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            this.isMoveInDateMoreThan3Years(),
        },
        {
          title: "First name",
          value: q.firstNameLicense,
          valName: "firstNameLicense",
          typeInput: "text",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please enter your first name",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            this.isMoveInDateMoreThan3Years(),
        },
        {
          title: "Middle name",
          value: q.middleNameLicense,
          valName: "middleNameLicense",
          typeInput: "text",
          options: null,
          validations: function () {
            return false;
          },
          messageError: "",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            this.isMoveInDateMoreThan3Years(),
        },
        {
          title: "Surname",
          value: q.surnameLicense,
          valName: "surnameLicense",
          typeInput: "text",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please enter your surname",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            this.isMoveInDateMoreThan3Years(),
        },
        {
          title: "What was your surname at birth?",
          value: q.surnameAtBirth,
          valName: "surnameAtBirth",
          typeInput: "text",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please enter your surname at birth",
          displayError: false,
          showIf: () => q.driving == "No" && q.renewLicense == "No" && q.lostLicense === "No",
        },
        {
          title: "What is your mother's maiden name?",
          value: q.motherMaidenName,
          valName: "motherMaidenName",
          typeInput: "text",
          options: null,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please enter your mother's maiden name",
          displayError: false,
          showIf: () => q.driving == "No" && q.renewLicense == "No" && q.lostLicense == "No",
        },
        {
          title: "What is your country of birth?",
          value: q.countryBirth,
          valName: "countryBirth",
          typeInput: "select",
          options: countries,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please select your country of birth",
          displayError: false,
          showIf: () => q.driving == "No" && q.renewLicense == "No" && q.lostLicense == "No",
        },


        {
          title: "What is your photocard number?",
          value: q.photocardNumber?.toLocaleUpperCase() ?? null,
          valName: "photocardNumber",
          typeInput: "text",
          options: null,
          tooltipImage: `${this.urlBucket}/form/images/photoCard.png`,

          validations: function () {
            const pattern = /^[A-Za-z]{2}[A-Za-z0-9][0-9]{7,9}$/;
            // return this.value?.length < 10 || this.value?.length > 12 || this.value == null;
            return !pattern.test(this.value) || this.value == null;
          },
          messageError: "Please review your photocard number",
          displayError: false,
          showIf: () =>
            q.driving == "No" &&
            q.renewLicense == "No" &&
            q.lostLicense === "No" &&
            q.license === "photocard",
        },
        {
          title: "What is your paper licence reference number?",
          value: q.paperlicense,
          valName: "paperlicense",
          typeInput: "text",
          options: null,
          tooltipImage: `${this.urlBucket}/form/images/paper licence reference number.png`,
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please enter your licence",
          displayError: false,
          showIf: () => q.license === "paper licence",
        },
        {
          title: "What is your driver's licence number? ",
          value: q.driversLicenseNumber?.toLocaleUpperCase() ?? null,
          valName: "driversLicenseNumber",
          typeInput: "text",
          options: null,
          tooltipImage: `${this.urlBucket}/form/images/drivingLicense.png`,
          validations: function () {
            // return false;
            return this.value?.length !== 16 || this.value == null;
          },
          messageError: "The driver's licence number must be 16 characters",
          displayError: false,
          showIf: () => q.driving == "No" && q.renewLicense == "No" && q.lostLicense == "No",
        },
        {
          title: "What is your licence issue number?",
          value: q.driversLicenseIssueNumber,
          valName: "driversLicenseIssueNumber",
          typeInput: "text",
          options: null,
          tooltipImage: `${this.urlBucket}/form/images/issueNumber.png`,
          validations: function () {
            return !/^\d{2}$/.test(this.value);
          },
          messageError: "Issue number must be 2 digits.",
          displayError: false,
          showIf: () => q.driving == "No" && q.renewLicense == "No" && q.lostLicense == "No",
        },
        {
          title:
            "I will receive a confirmation email from DVLA once your update is processed, then I agree to cut my old driving licence in two and return it to DVLA, Swansea SA99 1ZW",
          value: q.agreeCutOldlicense,
          valName: "agreeCutOldlicense",
          typeInput: "checkbox",
          options: null,
          validations: function () {
            return this.value == "" || !this.value;
          },
          messageError: "You must agree to continue",
          displayError: false,
          showIf: () => q.driving == "No" && q.renewLicense == "No" && q.lostLicense == "No",
          finishFlow: function () {
            return this.value == true && q.dvlaNeed != "Both";
          },
        },
      ].filter((item) => item.showIf() == true);

      let vehicleRegQuestions = [
        /* Vehicle Registration  */
        {
          title: "Are you the registered keeper of the vehicle?",
          value: q.registeredKeeper,
          valName: "registeredKeeper",
          typeInput: "select-box",
          options: ["Yes", "No"],
          validations: function () {
            return this.value == "";
          },
          messageError: "Please select if you the registered keeper of the vehicle",
          displayError: false,
          showIf: () => false,
        },
        {
          title: "Have you lost your V5C document?",
          value: q.lostV5CDocument,
          valName: "lostV5CDocument",
          typeInput: "select-box",
          options: ["Yes", "No"],
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please select if you lost your V5C document",
          displayError: false,
          showIf: () => true,
          finishFlow: function () {
            return this.value == "Yes";
          },
          warningMessage: function () {
            return (
              this.value == "Yes" &&
              "We're unable to update your vehicle's registered address as we require the V5C document reference number to complete this"
            );
          },
        },
        // {
        //   title: "What is your V5C reference number?",
        //   value: q.v5cNumber,
        //   valName: "v5cNumber",
        //   typeInput: "text",
        //   options: null,
        //   validations: function () {
        //     return !/^\d{11}$/.test(this.value);
        //   },
        //   messageError:
        //     "The V5C reference number is 11 digits and can be found on your V5c document.",
        //   //Hover over the \'i\' icon to know where to find it'
        //   displayError: false,
        //   showIf: () => q.registeredKeeper == "Yes" && q.lostV5CDocument == "No",
        // },
        {
          title: "What is your car registration?",
          value: q.vehiclesRegistered,
          valName: "vehiclesRegistered",
          typeInput: "vehicles-registration",
          options: null,
          validations: function () {
            return this.value[0]["carRegistration"] == "";
          },
          messageError: "Please add a valid car registration number",
          displayError: false,
          showIf: () => q.registeredKeeper == "Yes" && q.lostV5CDocument == "No",
        },
        {
          title: `Is ${moveFromPostCode} the postcode printed on your V5C?`,
          value: q.postCodePrintedV5C,
          valName: "postCodePrintedV5C",
          typeInput: "select-box",
          options: ["Yes", "No"],
          validations: function () {
            return this.value == "" || this.value == null;
          },
          messageError: "Please reply to this question",
          displayError: false,
          showIf: () => q.registeredKeeper == "Yes" && q.lostV5CDocument == "No",
        },
        {
          title: "What postcode is your vehicle registered at? ",
          value: q.postCodeVehicleRegistered,
          valName: "postCodeVehicleRegistered",
          typeInput: "text",
          options: null,
          validations: function () {
            return this.value?.length < 5 || this.value?.length > 7 || this.value == null;
          },
          messageError: "Postcode must be 5-7 characters long",
          displayError: false,
          showIf: () =>
            q.registeredKeeper == "Yes" &&
            q.lostV5CDocument == "No" &&
            q.postCodePrintedV5C == "No",
        },
        {
          title: "I agree to destroy my V5C document once I have received my new document",
          value: q.agreeDestroyOldV5C,
          valName: "agreeDestroyOldV5C",
          typeInput: "checkbox",
          options: null,
          validations: function () {
            return this.value == "" || !this.value;
          },
          messageError: "You must agree to continue",
          displayError: false,
          showIf: () => q.lostV5CDocument == "No",
          // q.registeredKeeper == "Yes"
          // && q.lostV5CDocument == "No"
          // && q.postCodePrintedV5C == "No",
          finishFlow: function () {
            return this.value == true;
          },
        },
      ].filter((item) => item.showIf() == true);

      if (q.dvlaNeed == "My licence" || q.dvlaNeed == "Both") {
        this.questions.push(...this.fillSubArray(licenseQuestions));
      }

      if (q.dvlaNeed == "My vehicle registration (V5C)" || q.dvlaNeed == "Both") {
        this.questions.push(...this.fillSubArray(vehicleRegQuestions));
      }

      // console.log(this.questions);
      this.currentQuestions = this.questions[this.step];
    },
    fillSubArray(arrayQuestions) {
      let arrayTempQ = [[]];

      arrayQuestions.forEach((item, index) => {
        if (index <= 2) {
          if (arrayQuestions[index].typeInput==="IntroText"){
            let group = [arrayQuestions[index]];
            arrayTempQ.push(group);
          }else{
            console.log("semete1 "+index);
            arrayTempQ[0].push(arrayQuestions[index]);
          }
        }else if (arrayQuestions[index].valName==="license" ||
            (arrayQuestions[index].valName==="licenseFront" || arrayQuestions[index].valName==="licenseBack" && this.dvlaQuestions.license==="photocard")){

            console.log("semete2 "+index);
            let group = [arrayQuestions[index]];
            arrayTempQ.push(group);


        } else if (index % 3 == 0 || this.dvlaQuestions.license==="paper licence") {
          console.log("semete3 "+index);
          let group = [
            arrayQuestions[0 + index],
            arrayQuestions[1 + index],
            arrayQuestions[2 + index],
          ].filter((item) => item != undefined);
          arrayTempQ.push(group);
        }
        console.log("el indice "+index);
      });

      return arrayTempQ;
    },
    async onClickButtonForm() {
      this.removeVehiclesInBlank(); //if current step is about vehicles

      await this.displaysErrorsIfExists();

      let areThereErrors = document.getElementsByClassName("dvla-question-error");

      if (areThereErrors.length > 0) {
        this.$store.commit("app/setField", ["displayLabelAboutData", true]);
      }

      let finishFlow = this.currentQuestions.find((item) => item.finishFlow && item.finishFlow());
      console.log("finishFlow", finishFlow);

      if (areThereErrors.length == 0) {
        this.$store.commit("app/setField", ["displayLabelAboutData", false]);

        this.setGroupQuestions();
        // debugger
        if (!finishFlow) {
          this.submitDVLAInfo();
        }
      }

      // let finishFlow = this.currentQuestions.find((item) => item.finishFlow && item.finishFlow());

      if (finishFlow && areThereErrors.length == 0) {
        this.sending = true;

        this.submitDVLAInfo(finishFlow)
          .then((result) => {
            // console.log(result);
            axiosCustom.get(`api/user/getDetails?email=${this.user.email}`).then(({ data }) => {
              console.log("getDetails - DVLA", data);
              this.$store.commit("app/setField", [
                "currentMove",
                data.moves[data.moves.length - 1],
              ]);
              this.sending = false;
              this.$store.commit("app/setField", ["showDVLAMiniForm", false]);
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },
    submitDVLAInfo(finishFlow) {
      console.log("dvlaQuestions", this.dvlaQuestions);
      let objMove = { ...this.currentMove };

      objMove["dvlaQuestions"] = this.dvlaQuestions;

      objMove["allDataProvided"] = finishFlow ? true : false;

      if (this.dvlaQuestions.dvlaNeed == "My licence") {
        objMove["dvlaQuestions"].dvlaNeed = "license";
        let date = new Date();
        date.setFullYear( date.getFullYear() - 4 );
        objMove.dvlaQuestions['datePreviousAddress']=date;
        objMove.dvlaQuestions['whenMoveIntoAddress']=date;

      }

      if (this.dvlaQuestions.dvlaNeed == "My vehicle registration (V5C)") {
        objMove["dvlaQuestions"].dvlaNeed = "vehicle";
      }

      if (this.dvlaQuestions.dvlaNeed == "Both") {
        objMove["dvlaQuestions"].dvlaNeed = "both";
      }

      // datePreviousAddress when it's equal to DOB


     /*  if (objMove.dvlaQuestions['datePreviousAddress'] instanceof Date) {

        let obj = Object.assign({}, objMove);
        let datePreviousAddress = new Date(obj.dvlaQuestions.datePreviousAddress); ;

        if (
          datePreviousAddress.toISOString().slice(0, 10) == obj.userDetails.dateOfBirth
          ) {

            datePreviousAddress.setDate(datePreviousAddress.getDate() + 1);
            objMove.dvlaQuestions['datePreviousAddress'] = datePreviousAddress ;
        }

        // console.log(objMove.dvlaQuestions.datePreviousAddress, obj.userDetails.dateOfBirth)
      } */

      console.log("objMoveInDVLA-miniform", objMove);

      // return;
      return axiosCustom
        .post("user/utils/fillInFormInDashboard", { ...objMove })
        .then((response) => console.log(response));
    },
    async setGroupQuestions() {
      this.step = this.step + 1;
      this.step = this.step > this.questions.length - 1 ? this.step - 1 : this.step;
      this.currentQuestions = this.questions[this.step];
    },
    provideLater() {
      this.$store.commit("app/setField", ["displayLabelAboutData", true]);

      if (this.questions.length - 1 == this.step) {
        // this.$store.commit("app/setField", ["showDVLAMiniForm", false]);
        return;
      } else {
        this.questions.length - 1 > this.step && this.setGroupQuestions();
      }
    },
    displaysErrorsIfExists() {
      let errorV5cNumber =
        document.querySelector(".input-v5c-number") &&
        this.dvlaQuestions["vehiclesRegistered"].find((e) => this.validateV5CNumber(e.v5cNumber));

      if (errorV5cNumber) {
        this.error.v5cNumber = true;
      } else {
        this.error.v5cNumber = false;
      }

      return new Promise((resolve, reject) => {
        this.currentQuestions.forEach((item, i, array) => {
          item.displayError = item["validations"] ? item.validations() : false;
          if (array.length - 1 == i) {
            resolve(i);
          }
        });
      });
    },
    back() {
      console.log("back apretado")
      this.step = this.step == 0 ? 0 : this.step - 1;
      this.currentQuestions = this.questions[this.step];
    },
    updateValue(value, index, valName) {
      // console.log("updateValue", value);
      this.dvlaQuestions[valName] = value;
      this.currentQuestions[index].value = value;

      this.displayQuestions();
    },
    updateValueExtractedText(value) {
      this.dvlaExtractedText = value;
      if (this.dvlaExtractedText.photoId!== "" && this.dvlaExtractedText.photoId!==null){
        this.dvlaQuestions.photocardNumber=this.dvlaExtractedText.photoId;
      }else{
        if (this.dvlaExtractedText.firstName !==""){
          this.frontUploadSuccess=true;
        }
        this.dvlaQuestions.firstNameLicense=this.dvlaExtractedText.firstName;
        this.dvlaQuestions.middleNameLicense=this.dvlaExtractedText.middleName;
        this.dvlaQuestions.surnameLicense=this.dvlaExtractedText.lastName;
        this.dvlaQuestions.titleLicense=this.dvlaExtractedText.title;
        this.dvlaQuestions.renewLicense=this.dvlaExtractedText.expired?"Yes":"No";
        this.dvlaQuestions.driversLicenseNumber=this.dvlaExtractedText.driversLicenceNumber;
        this.dvlaQuestions.driversLicenseIssueNumber=this.dvlaExtractedText.driversLicenceIssueNumber;
        this.dvlaQuestions.addressPrintedLicense=this.dvlaExtractedText.address;

        this.dvlaQuestions.postCodePrinted=this.dvlaExtractedText.postcode;
        if (this.dvlaQuestions.postCodePrinted===this.currentMove["moveDetails"].moveToPostcode){
          this.dvlaQuestions.previousAddressPrinted="Yes";
        }else{
          this.dvlaQuestions.previousAddressPrinted="No";
        }
        this.dvlaQuestions.countryBirth=this.dvlaExtractedText.countryOfBirth;
        this.dvlaQuestions.provisionalLicense=this.dvlaExtractedText.provisionalLicence?"Yes":"No";
        console.log("prov lice", this.dvlaQuestions.provisionalLicense);
        console.log("prov lice ext", this.dvlaExtractedText.provisionalLicence);
      }

      this.displayQuestions();
    },
    updateV5CNumber(value, vehicle, i) {
      vehicle["v5cNumber"] = value;
      this.dvlaQuestions["vehiclesRegistered"][i] = vehicle;

      //to trigger change detection
      this.dvlaQuestions["vehiclesRegistered"].splice(
        this.dvlaQuestions["vehiclesRegistered"].length
      );
    },
    isMoveInDateMoreThan3Years() {
      let threeYearsBefore = new Date();
      threeYearsBefore.setFullYear(threeYearsBefore.getFullYear() - 3);

      const dateToProcess = this.currentMove.moveDetails.moveInDate;

      let moveInDate =
        dateToProcess instanceof Date
          ? dateToProcess
          : new Date(dateToProcess.replaceAll("-", "/"));
      const is = threeYearsBefore < moveInDate;

      return is;
    },
    //Vehicle Registration Flow
    messageVehicleRegistration(dataTarget) {
      let value = document.querySelector(`input[data-target="${dataTarget}"]`)?.value;
      return value?.length > 0 && !this.validateVehicleRegistration(value);
    },
    validateVehicleRegistration(value) {
      return /(^[A-Z]{2}[0-9]{2} [A-Z]{3}$)|(^[A-Z][0-9]{1,3} [A-Z]{3}$)|(^[A-Z]{3} [0-9]{1,3}[A-Z]$)|(^[0-9]{1,4} [A-Z]{1,2}$)|(^[0-9]{1,3} [A-Z]{1,3}$)|(^[A-Z]{1,2} [0-9]{1,4}$)|(^[A-Z]{1,3} [0-9]{1,3}$)/.test(
        value
      );
    },
    addVehicle() {
      let arrayTemp = [...this.dvlaQuestions["vehiclesRegistered"]];
      arrayTemp.push({ carRegistration: "", info: {}, img: "", v5cNumber: "" });
      this.dvlaQuestions["vehiclesRegistered"] = arrayTemp;
    },
    vehicleRegistration(value, index) {
      //to trigger vue change detection
      this.dvlaQuestions["vehiclesRegistered"][index].carRegistration = value;

      /* we consult the vehicle information with the DVLA */
      if (this.validateVehicleRegistration(value)) {
        let info = fetch(`${this.baseURL}/dvlaInfo/getVehicleInfo?stream=${value}`).then(
          (response) => response.json()
        );
        let img = fetch(`${this.baseURL}/dvlaInfo/getVehicleImg?stream=${value}`).then((response) =>
          response.json()
        );

        Promise.all([info, img])
          .then((res) => {
            // console.log({ info: res[0], img: res[1] });

            let arrayTemp = [...this.dvlaQuestions["vehiclesRegistered"]];
            //
            arrayTemp[index].carRegistration = value;
            arrayTemp[index].info = res[0];
            arrayTemp[index].notFoundIt = false;
            arrayTemp[index].img =
              res[1]?.Response?.DataItems?.VehicleImages?.ImageDetailsList[0]["ImageUrl"];
            //
            this.dvlaQuestions["vehiclesRegistered"] = arrayTemp;
          })
          .catch((e) => {
            console.error(e);

            let vehicle = this.dvlaQuestions["vehiclesRegistered"][index];
            vehicle.notFoundIt = true;

            //with that we update an element of the vehicle array, so it is reactive.
            this.dvlaQuestions["vehiclesRegistered"].splice(index, 1, vehicle);

            // console.log(this.dvlaQuestions["vehiclesRegistered"][index])
          });
      }
      /* we consult the vehicle information with the DVLA */
    },
    ifEmptyDeleteVehicle(event, index) {
      if (
        event.key == "Backspace" &&
        event.target.value == "" &&
        this.dvlaQuestions["vehiclesRegistered"].length >= 2
      ) {
        // console.log(event.target.value,index);
        let arrayTemp = this.dvlaQuestions["vehiclesRegistered"];
        arrayTemp.splice(index, 1);
        this.dvlaQuestions["vehiclesRegistered"] = arrayTemp;
        this.displayQuestions();
      }
    },
    removeVehiclesInBlank() {
      let hasVehicle = this.dvlaQuestions["vehiclesRegistered"].find(
        (v) => Object.keys(v.info).length > 0
      );
      // console.log(hasVehicle);

      if (hasVehicle) {
        let vehicles = this.dvlaQuestions["vehiclesRegistered"].filter(
          (v) => Object.keys(v.info).length != 0
        );
        // console.log(vehicles);
        this.dvlaQuestions["vehiclesRegistered"] = vehicles;
      }
    },
    deleteVehicle(index) {
      let arrayTemp = this.dvlaQuestions["vehiclesRegistered"];
      arrayTemp.splice(index, 1);
      this.dvlaQuestions["vehiclesRegistered"] = arrayTemp;
      this.displayQuestions();
    },
    validateV5CNumber(number) {
      return !/^\d{11}$/.test(number);
    },
    toggleTooltip(id) {
      let element = document.getElementById(id);
      element.classList.toggle("visible");
    },
  },
  computed: {
    ...mapState("app", ["baseURL", "urlBucket", "user", "tableDataServices", "currentMove"]),
  },
  watch: {
    // dvlaQuestions: {
    //   handler(val, oldVal) {
    //     console.log(oldVal, val);
    //   },
    //   deep: true,
    // },
    currentMove: function (newValue) {
      console.log({ dvlaQuestions: newValue.dvlaQuestions });

      // for (const [key, value] of Object.entries(newValue.dvlaQuestions)) {
      //   console.log({ [`${key}`]: value });
      // }
    },
  },
};
</script>
<style lang="scss">
.dvla-mini-form {
  h2,
  h4,
  p {
    color: white;
  }

  p,
  .v-select {
    font-size: 15px;
  }

  .form {
    border: 3px solid #18a8d6;
    background-color: #1e202d;
    border-radius: 0.428rem;

    .container-icon-shield {
      position: absolute;
      left: 0;
      top: -30px;
      display: flex;
      justify-content: center;

      .icon {
        /*background-color: #1e202d;*/
        border-radius: 15px;
      }
    }
  }

  .back-button {
    font-size: 35px;
    color: white;
    cursor: pointer;
  }

  .step {
    height: 12px;
    margin: 3px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    cursor: pointer;
  }

  .step-active {
    background: white;
    background: linear-gradient(
      90deg,
      rgba(10, 119, 254, 1) 0%,
      rgba(10, 119, 254, 0.4962359943977591) 65%,
      rgba(179, 0, 255, 1) 100%
    );
    transform: perspective(1px) translateZ(0);
  }

  input[type="text"],
  input[type="number"] {
    outline: none;
    border-radius: 0.375rem;
    border: none;
    color: black;
  }

  .dvla-question-error,
  .dvla-question-warning {
    font-size: 14px;
    color: #c64a45;
  }

  .vdp-datepicker {
    color: black;
    input {
      padding: 1rem !important;
      width: 100% !important;
    }
  }

  .button-sloth {
    background-color: #15799b;
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 11px 16px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter 0.2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
  }

  .button-sloth:after {
    background-color: #18a8d6;
    background-clip: padding-box;
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .button-sloth:main,
  .button-sloth:focus {
    user-select: auto;
  }

  .button-sloth:hover:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
  }

  .info-portal {
    background-color: white;
    border-radius: 12px;

    h5 {
      color: #23a9d7;
    }

    p {
      color: black;
    }
  }

  .box-answers-services {
    width: 110px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid transparent;
    background-color: #fff;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;

    p {
      color: #000;
    }
  }

  .select {
    .vs__search,
    .vs__search:focus {
      font-size: 14px;
    }
    .vs__selected {
      color: black;
    }

    .vs__dropdown-toggle {
      padding: 0.6rem !important;
      border-radius: 0.375rem;
      color: black;
      background-color: white;
    }

    .vs__dropdown-menu {
      color: black !important;
    }
  }

  .pretty {
    label {
      font-size: 22px;
    }
  }

  .box-answers {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    min-width: 55px;
    margin-right: 10px;
    border: 1px solid transparent;
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
  }

  .checked {
    border: 1px solid #2ec989 !important;
    background-color: #1e9d68 !important;
    color: #fff !important;
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }

  .vehicle-registration {
    .flag-box {
      background-color: #1f476f;
      height: 55px;
      border-radius: 0.4rem 0 0 0.4rem;
      padding: 7px;
      h5 {
        color: white;
      }
    }

    .input-vehicle-registration {
      padding: 5.5px;
      padding-left: 15px;
      background-color: #f9c038;
      border-radius: 0 0.375rem 0.375rem 0;
      color: #1f476f;
      font-size: 30px;
    }

    .card-vehicle {
      position: relative;
      border: 2px solid #22a8d6;
      border-radius: 8px;

      p {
        text-align: justify;
        text-justify: inter-word;
      }
    }

    .remove,
    .add {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
