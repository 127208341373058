<template>
  <div v-if="tableData" class="col px-0 w-100">
    <div class="row d-flex justify-content-between">
      <!-- <div class="col-12 col-md-6">
       <h4 class="title-change-address" >Your move on {{ moveInDate }}</h4>
      </div> -->
      <div class="col text-right mb-2" v-if="moves.length > 1">
        <div class="points-moves">
          <span
            :class="`dot ml-1 ${position == index ? 'active' : ''}`"
            :style="{ 'background-color': position == index ? colorPartner : null }"
            @click="
              position = index;
              fillInMovingTable();
            "
            v-for="(item, index) in moves"
            :key="index"
          ></span>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div
      v-if="tableData.length > 0"
      class="container-table animate__animated animate__fadeInUp animate__faster"
    >
      <div
        v-for="(data, index) in tableData"
        :key="index"
        class="content-row text-black"
        :class="{
          'bg-info-light': index % 2 !== 0,
        }"
      >
        <div class="row px-1 px-md-4" @click="toggleDetailsRow(index)">
          <div
            class="col-12 col-md py-1 d-flex justify-content-center align-items-center company-logo-item cursor-pointer"
          >
            <div class="img-container">
              <b-img :src="data.icon" alt="avatar img" style="" />
            </div>
          </div>

          <div
            class="col-12 col-md d-flex align-items-center justify-content-center cursor-pointer"
          >
            <div>
              <div class="font-weight-normal px-2 pl-sm-3 pr-sm-3" style="font-size: 16px">
                <span class="" v-if="data.info.length >= 20">{{ data.info }}</span>

                <span v-else-if="data.status == 'Waiting'">
                  <!-- {{ data.title }} has advised us against triggering this update until
                  {{ data.waitingDays ? `${data.waitingDays} days before` : "your move date" }} -->
                  We're holding off on sending this update until it's closer to your move date 😊
                </span>

                <span v-else-if="data.status == 'Processing'">
                  We have sent your update to {{ data.title }} and their operations team will start
                  working on it</span
                >

                <span v-else-if="data.status == 'Update Sent'">
                  Nice work, we’ve sent your address update 🙌. {{ data.title }} will now process
                  this
                </span>

                <span v-else-if="data.status == 'Action'">
                  We have been unable to match your details with the DVLA database. Please go to
                  Pending Actions</span
                >

                <!-- <span v-else-if="data.status == 'Updated'">
                  {{ data.title }} has received your update. They will start working on your address
                  change.</span
                > -->
              </div>
            </div>
          </div>

          <div
            class="col-12 py-1 col-md d-flex justify-content-center align-items-center buttons-items cursor-pointer"
          >
            <div
              class="font-weight-bolder btn-status"
              v-bind:class="[
                data.status == 'Waiting'
                  ? 'wait-status'
                  : data.status == 'Processing'
                  ? 'processing-status'
                  : data.status == 'Update Sent'
                  ? 'update-sent-status'
                  : data.status == 'Action'
                  ? 'pending-action-status'
                  : '',
              ]"
            >
              {{ data.status }}
            </div>
            <div class="font-weight-bolder table-item-icon ml-1">
              <i
                class="fa-solid"
                :class="{
                  'fa-chevron-up': data.active,
                  'fa-chevron-down': !data.active,
                  'chevron-wait-status': data.status == 'Waiting',
                  'chevron-processing-status': data.status == 'Processing',
                  'chevron-sent-status': data.status == 'Update Sent',
                  'chevron-action-status': data.status == 'Action',
                }"
              ></i>
            </div>
          </div>
        </div>

        <div
          class="content-row-detail px-1 px-md-5"
          :class="{
            active: data.active,
          }"
        >
          <div class="collapse">
            <div class="col-12">

              <!-- Stepper Bar -->
              <div class="d-flex justify-content-center w-100 mt-3">
                <div class="container-stepper-bar">
                  <div class="step-bar">
                    <div
                      :class="`step step-${index + 1} ${setStatusStepper(data,index + 1)}`"

                      v-for="(item, index) in [
                        'Waiting to send update',
                        'Processing Update',
                        'Update Sent',
                      ]"
                      :key="index"
                    >
                      <div
                        class="circle"
                      >
                        <i class="fa-solid fa-check"> </i>
                      </div>
                      <span class="step-text">{{ item }}</span>
                      <div class="line" :style="{'background': (index + 1 == data.step ? '#cececf' : '' )}" v-if="index < 2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Stepper Bar -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Table -->
  </div>
</template>

<script>
import { BCard, BTable, BImg, VBTooltip } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

import { BButtonGroup, BButton } from "bootstrap-vue";
import moment from "moment";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapState, mapGetters } from "vuex";
import axiosCustom from '@/libs/axios'

export default {
  components: {
    /*     BCard, */
    BTable,
    BImg,
    VueApexCharts,
    BButtonGroup,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      datesDebug: {},
      // stepData: [/* "Letter of Authority", */ "Waiting Until Your Move Date", "Processing Information", /* "Certifying Communication" ,*/ "Update sent to Supplier", "Update received by Supplier"],
      tableData: [],
      moves:
        [] /* JSON.parse(localStorage.getItem('userData')).moves.sort((a,b)=>new Date(b.submissionDate) - new Date(a.submissionDate)) */,
      position: 0,
      moveInDate: "",
      month: moment().month() + 1,
      day: moment().daysInMonth(),
    };
  },
  mounted() {
    let user = Object.assign({}, this.user);
    this.moves = user.moves
      .filter((m) => m["submissionDateFormatted"] !== null)
      .sort((a, b) => new Date(b.submissionDate) - new Date(a.submissionDate));
    if (this.moves !==null && this.moves.length>0){
      this.position=this.moves.length -1;
    }
    this.fillInMovingTable();
    this.selectedBanksList = [];
  },
  methods: {
    async fillInMovingTable() {
      this.tableData = [];
      let response = await axiosCustom.get(`api/user/getDetails?email=${this.user.email}`);
      const moves = response.data.moves;
      const movesData = moves[this.position];
      this.$store.commit("app/setField", ["currentMove", movesData]);
      let data = { ...movesData["councils"], ...movesData["services"] };
      // const hours =
      //   (new Date().getTime() - new Date(movesData.submissionDate).getTime()) / 1000 / 60 / 60;
      let { actionUpdates } = movesData;

      if (movesData.services!==null &&
          movesData.services.selectedBanksDetails!==null &&
          movesData.services.selectedBanksDetails !==undefined){

        let size = movesData.services.selectedBanksDetails.length;
        for (let k=0; k<size ;k++){
          this.selectedBanksList.push (movesData.services.selectedBanksDetails[k].name)
        }
      }

      console.log({ actionUpdates: actionUpdates });

      const validationServices = () => {
        //{estimatedDaysToProcess: ...} -> 'to' will be the estimated number of days, it is used to fill in the graph progress and assign the status according to validation.

        //Validate Action Update
        const valActionUpdate = (
          action,
          title,
          icon,
          estimatedDaysToProcess,
          estimatedTime,
          informName,
          waitingDays,
          nameProvider
        ) => {
          let obj = {
            title,
            icon: `${this.$urlBucketImage}/icons/${icon}`,
            estimatedDaysToProcess,
            estimatedTime,
            active: false,
            step: 1,
            info: "",
          };

          obj[`${informName}`] = true;

          if (icon.includes("logo")) obj.icon = `${this.$urlBucketImage}/form/${icon}`;
          if (icon.includes("banks/") || icon.includes("mobile/"))
            obj.icon = `${this.$urlBucketImage}/icons/${icon}`;
          if (action == "Councils") obj.icon = `${this.$urlBucketImage}/icons/councils/${icon}`;

          if (nameProvider) {
            obj["nameProvider"] = nameProvider;
          }

          function actionUpdated() {
            if (Array.isArray(actionUpdates) && actionUpdates.length > 0) {
              // if ( action == 'DVLA-v5c' || action == 'DVLA-License') debugger

              let actionUpdatesFiltered = actionUpdates.filter((i) => i.action == action);

              const has100Status = actionUpdatesFiltered.find((item) => item.status == "100");

              let actionItem =
                has100Status ??
                actionUpdatesFiltered.find(
                  (item, index, array) => item.action == action && array.length - 1 === index
                );

              //Here we evaluate whether the service has an actionUpdate to associate with it
              if (actionItem) {
                obj["progressActionUpdate"] = 0;
                obj["info"] = "" /* actionItem["message"] */;

                if (actionItem.status == "100") {
                  obj["status"] = "Sent";
                  obj["progressActionUpdate"] = 100;
                  obj["lastModificationActionUpdate"] = actionItem["lastModification"];

                  if (
                    actionItem.message &&
                    actionItem.message.length &&
                    (action == "DVLA-v5c" ||
                      action == "DVLA-License" ||
                      obj["progressActionUpdate"] !== 0)
                  ) {
                    obj["info"] = actionItem["message"];
                  }

                  if (typeof actionItem.referenceId == "string") {
                    obj["referenceId"] = actionItem["referenceId"];
                  }
                }
                // else {
                //   obj['status'] = 'Waiting'
                // }
              }
              //Here we evaluate whether the service has an actionUpdate to associate with it
            }
          }

          actionUpdated();

          //To calculate date to display in /estimatedDate calendar image

          let s =
            typeof movesData["submissionDateFormatted"] === "string"
              ? movesData["submissionDateFormatted"].split("T")
              : new Date(movesData["submissionDateFormatted"]).toISOString().split("T");

          //Submission Date
          let split = s[0].split("-");
          let submissionDate = moment(`${split[2]}/${split[1]}/${split[0]}`, "DD/MM/YYYY", true);

          //Estimated Date
          let split1 = s[0].split("-");
          let estimatedDate = moment(`${split1[2]}/${split1[1]}/${split1[0]}`, "DD/MM/YYYY", true);

          //Move In Date
          let split2;
          try {
            split2 = movesData.moveDetails["moveInDate"].split("-");
          } catch (e) {
            split2 = new Date(movesData.moveDetails["moveInDate"]).toISOString().split("T")[0];
          }

          let moveInDate = moment(`${split2[2]}/${split2[1]}/${split2[0]}`, "DD/MM/YYYY", true);

          // console.log(submissionDate,estimatedDate,moveInDate);
          this.moveInDate = moveInDate;
          let currentDate = moment();
          let dateBeforeMovingDate = undefined; //variable with the date with the waiting days subtracted

          //only for debugging
          this.datesDebug = {
            submissionDate: estimatedDate.format("DD/MM/YYYY"),
            moveInDate: moveInDate.format("DD/MM/YYYY"),
          };
          //only for debugging

          function setStatusService() {
            // if(obj.title == 'DVLA-v5c') debugger

            const setInfo = (messageOptional) =>
              obj["info"].length > 20 ? obj["info"] : messageOptional;

              obj["status"] = "Waiting";
              obj["step"] = 1;
              obj["info"] = setInfo("");

            if (!waitingDays) {

              // obj["status"] = "Waiting";
              // obj["step"] = 1;
              // obj["info"] = setInfo("");

              if (
                submissionDate.valueOf() <= moveInDate.valueOf() &&
                currentDate.valueOf() <= moveInDate.valueOf()
              ) {
                moveInDate.add(estimatedDaysToProcess, "days");
                estimatedDate = moveInDate;

                if (!obj.hasOwnProperty("progressActionUpdate")) {
                  return;
                }
              }
            }

            if (waitingDays) {
              // obj["waitingDays"] = waitingDays;
              // debugger
              dateBeforeMovingDate = moveInDate.subtract(waitingDays, "days");
              // console.log( obj.title, 'moveInDate - (waiting days)', dateBeforeMovingDate.format("DD/MM/YYYY"));
              // obj['status'] = 'Waiting'
            }

            // const statusProcessing = () => {
            //   obj["status"] = "Processing";
            //   obj["step"] = 2;
            //   obj["info"] = setInfo("");
            // };


            //if we are in the first 24 hours

            const isWithin24Hours = (date) => {
              const dateProvided = moment(date);
              const currentDate = moment();

              return currentDate.diff(dateProvided, 'hours') < 24;
            }

            // let valid = currentDate.isSameOrAfter(movesData.moveDetails["moveInDate"])
            // let valid2 = !obj.hasOwnProperty("progressActionUpdate")
            // let valid3 = isWithin24Hours(movesData.submissionDate)

            if (
              (!obj.hasOwnProperty("progressActionUpdate") || isWithin24Hours(movesData.submissionDate))
              || currentDate.isSameOrAfter(movesData.moveDetails["moveInDate"])
            ) {
              obj["status"] = "Waiting";
              obj["step"] = 1;
              obj["info"] = "We are double-checking the details you have given us";
            }

            // if (isWithin24Hours(movesData.submissionDate)) {
            //   statusProcessing();
            // }
            // if we are in the first 24 hours


            //when the moveInDate - (waiting days) is after the submissionDate(estimatedDate)
            if (dateBeforeMovingDate?.isAfter(estimatedDate)) {
              // debugger
              dateBeforeMovingDate.add(estimatedDaysToProcess, "days");
              estimatedDate = dateBeforeMovingDate;

              if (
                obj.hasOwnProperty("progressActionUpdate") &&
                obj["progressActionUpdate"] == 100
              ) {
                //means that it has actionUpdate in sent
                obj["status"] = "Update Sent";
                obj["step"] = 3;
                obj["info"] = "";
              }
              // else if (!obj.hasOwnProperty("progressActionUpdate")) {
              //   statusProcessing();
              // }
            }
            //when the moveInDate - (waiting days) is after the submissionDate(estimatedDate)

            //when the dateBeforeMovingDate(moveInDate - {waiting days})** <  submissionDate(estimatedDate)
            if (
              dateBeforeMovingDate?.isBefore(estimatedDate) || submissionDate.isSameOrAfter(moveInDate)
            ) {
              // debugger
              submissionDate.add(estimatedDaysToProcess, "days"); //<-then here we take submissionDate as the start date of the update
              estimatedDate = submissionDate;

              if (
                obj.hasOwnProperty("progressActionUpdate") &&
                obj["progressActionUpdate"] == 100
              ) {
                obj["status"] = "Update Sent";
                obj["step"] = 3;
                obj["info"] = "";
              }
              // else if (!obj.hasOwnProperty("progressActionUpdate")) {
              //   statusProcessing();
              // }
            }
             //when the dateBeforeMovingDate(moveInDate - {waiting days})** <  submissionDate(estimatedDate)

            //when there are no waiting days, moveInDate is less than submissionDate and the update date starts at moveInDate
            if (!dateBeforeMovingDate && submissionDate.isSameOrBefore(moveInDate)) {
              moveInDate.add(estimatedDaysToProcess, "days");
              estimatedDate = moveInDate;
              // debugger
              if (
                obj.hasOwnProperty("progressActionUpdate") &&
                obj["progressActionUpdate"] == 100
              ) {
                obj["status"] = "Update Sent";
                obj["step"] = 3;
                obj["info"] =  "";
              }
              // else if (!obj.hasOwnProperty("progressActionUpdate")) {
              //   statusProcessing();
              // }
            }

            // debugger
          }

          function setStatusServiceWithLOA() {
            // if(obj.title == 'TV Licence') debugger
            // if(obj["progressActionUpdate"]) debugger

            let today = moment();

            //Setting status
            // if (
            //   movesData["loa"]?.status == "notSent" && !obj.hasOwnProperty("progressActionUpdate")
            // ) {
            //   obj["status"] = "Waiting";
            //   obj["step"] = 1;
            // }

            if (movesData["loa"]?.status == "Sent" && !obj.hasOwnProperty("progressActionUpdate")) {
              obj["status"] = "Waiting";
              obj["step"] = 1;
              obj["info"] =
                "We have sent you a Letter of Authority for you to sign it. Please proceed so that we can action the updates";
            }

            // let valid = today.isSameOrAfter(estimatedDate)
            // debugger
            if ( movesData["loa"]?.status == "signed") {
              if (obj.hasOwnProperty("progressActionUpdate") &&
                obj["progressActionUpdate"] !== 100
                 && today.isSameOrAfter(estimatedDate) ) {
                  obj["status"] = "Processing";
                  obj["step"] = 2;
                  obj["info"] = "";
              }
            }
          }

          setStatusService();

          //To calculate date to display in estimatedDate/calendar image
          // obj["estimatedDate"] = estimatedDate.format("DD/MM/YYYY");

          //Letter of Autority - LOA
          // if (obj["title"] != "DVLA-v5c" && obj["title"] != "DVLA-License") {
          //   setStatusServiceWithLOA();
          // }

          setStatusServiceWithLOA();

          //Action Status - (this is for DVLA v5c and DVLA license) Display this if there’s a pending action associated to it

          if (this.currentMove['pendingActions'] != null) {
            if ((obj["title"] == "DVLA-License" && this.currentMove.pendingActions['dvlaLicence'].pending) ||
            (obj["title"] == "DVLA-v5c" && this.currentMove.pendingActions['dvlaVehicle'].pending)) {
              obj["status"] = "Action";
              obj["step"] = 4;
              obj["info"] = "";
            }
          }

          this.tableData.push(obj);
        };

        //Energy
        if (
          data.selectedEnergySupplier
        ) {
          valActionUpdate(
            "Energy",
            data.selectedEnergySupplier,
            `${data.selectedEnergySupplier}.png`,
            5,
            "5 days",
            "informEnergy",
            14
          );
        }

        if (
          data.selectedGasSupplier
        ) {
          valActionUpdate(
            "Energy",
            data.selectedGasSupplier,
            `${data.selectedGasSupplier}.png`,
            5,
            "5 days",
            "informEnergy",
            14
          );
        }

        //Water
        if ( data.informOldWater && data.oldWater  ) {
          valActionUpdate(
            "Water",
            data.oldWater,
            `${data.oldWater}.png`,
            28,
            "4 weeks",
            "informOldWater",
            14
          );
        }

        if ( data.informNewWater && data.newWater ) {
          valActionUpdate(
            "Water",
            data.newWater,
            `${data.newWater}.png`,
            28,
            "4 weeks",
            "informNewWater",
            14
          );
        }

        //Council

        if (data.informOldCouncil && data.oldCouncil && data.newCouncil !== data.oldCouncil) {
          valActionUpdate(
            "Councils",
            data.oldCouncil,
            `${data.oldCouncil}.png`,
            28,
            "4 weeks",
            "informOldCouncil",
            28
          );
        }
        if (data.informNewCouncil && data.newCouncil) {
          valActionUpdate(
            "Councils",
            data.newCouncil,
            `${data.newCouncil}.png`,
            28,
            "4 weeks",
            "informNewCouncil",
            28
          );
        }

        //Tv Licence
        if (data.informTVLicense) {
          valActionUpdate(
            "TVLicence",
            "TV Licence",
            "tvlicense.png",
            28,
            "4 weeks",
            "informTVLicense",
            14
          );
        }

        //Lottery
        if (data.informLottery) {
          valActionUpdate(
            "Lottery",
            "Lottery",
            "postcodelottery.png",
            7,
            "1 week",
            "informLottery",
            7
          );
        }

        //Loyalty
        if (data.selectedLoyalties && data.selectedLoyalties.includes("Tesco")) {
          valActionUpdate("Loyalties", "Tesco", "tesco.png", 7, "1 week", "informLoyalty");
        }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("Matalan")) {
          valActionUpdate("Loyalties", "Matalan", "matalan.png", 7, "1 week", "informLoyalty");
        }
        // if (data.selectedLoyalties && data.selectedLoyalties.includes("M&S")) {
        //   valActionUpdate("Loyalties", "M&S", "M&S.png", 7, "1 week", "informLoyalty");
        // }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("Paperchase")) {
          valActionUpdate(
            "Loyalties",
            "Paperchase",
            "paperchase.png",
            7,
            "1 week",
            "informLoyalty"
          );
        }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("Ikea")) {
          valActionUpdate("Loyalties", "Ikea", "ikea.png", 7, "1 week", "informLoyalty");
        }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("TKMAXX")) {
          valActionUpdate("Loyalties", "TKMAXX", "tkmaxx.png", 7, "1 week", "informLoyalty");
        }
        /* if (data.selectedLoyalties && data.selectedLoyalties.includes("Iceland")) {
          valActionUpdate("Loyalties", "Iceland", "iceland.png", 7, "1 week", "informLoyalty");
        } */
        if (data.selectedLoyalties && data.selectedLoyalties.includes("H&B")) {
          valActionUpdate("Loyalties", "Holland & Barrett", "hb.png", 7, "1 week", "informLoyalty");
        }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("Costco")) {
          valActionUpdate("Loyalties", "Costco", "costco.png", 7, "1 week", "informLoyalty");
        }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("John Lewis")) {
          valActionUpdate("Loyalties", "John Lewis", "John Lewis.png", 7, "1 week", "informLoyalty");
        }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("Lidl")) {
          valActionUpdate("Loyalties", "Lidl", "Lidl.png", 7, "1 week", "informLoyalty");
        }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("Boots")) {
          valActionUpdate("Loyalties", "Boots", "Boots.png", 7, "1 week", "informLoyalty");
        }
        if (data.selectedLoyalties && data.selectedLoyalties.includes("Waitrose")) {
          valActionUpdate("Loyalties", "Waitrose", "waitrose.png", 7, "1 week", "informLoyalty");
        }

        //Gyms
        if (data.selectedGyms && data.selectedGyms.includes("Fitness first")) {
          valActionUpdate("Gyms", "Fitness first", "fitnessfirst.png", 28, "4 weeks", "informGyms");
        }
        if (data.selectedGyms && data.selectedGyms.includes("The Gym")) {
          valActionUpdate("Gyms", "The Gym", "thegym.png", 28, "4 weeks", "informGyms");
        }
        if (data.selectedGyms && data.selectedGyms.includes("Nuffield Health")) {
          valActionUpdate("Gyms", "Nuffield Health", "nuffield.png", 28, "4 weeks", "informGyms");
        }
        if (data.selectedGyms && data.selectedGyms.includes("Puregym")) {
          valActionUpdate("Gyms", "Puregym", "puregym.png", 28, "4 weeks", "informGyms");
        }
        if (data.selectedGyms && data.selectedGyms.includes("JD")) {
          valActionUpdate("Gyms", "JD", "jd.png", 28, "4 weeks", "informGyms");
        }
        if (data.selectedGyms && data.selectedGyms.includes("Virgin Active")) {
          valActionUpdate("Gyms", "Virgin Active", "virginactive.png", 28, "4 weeks", "informGyms");
        }
        if (data.selectedGyms && data.selectedGyms.includes("Xercise4less")) {
          valActionUpdate("Gyms", "Xercise4less", "xercise4less.png", 28, "4 weeks", "informGyms");
        }

        //Charity
        if (data.selectedCharities && data.selectedCharities.includes("ActionAid")) {
          valActionUpdate(
            "Charities",
            "ActionAid",
            "actionaid.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Alzheimer's Society")) {
          valActionUpdate(
            "Charities",
            "Alzheimer's Society",
            "alzheimer.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Amnesty International")) {
          valActionUpdate(
            "Charities",
            "Amnesty International",
            "amnesty.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Barnardos")) {
          valActionUpdate(
            "Charities",
            "Barnardos",
            "Barnardos.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Book Trust")) {
          valActionUpdate(
            "Charities",
            "Book Trust",
            "booktrust.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("British Heart Foundation")) {
          valActionUpdate(
            "Charities",
            "British Heart Foundation",
            "britishheart.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (
          data.selectedCharities &&
          data.selectedCharities.includes("Campaign for Nuclear Disarmament")
        ) {
          valActionUpdate(
            "Charities",
            "Campaign for Nuclear Disarmament",
            "nuclear.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Cancer Research UK")) {
          valActionUpdate(
            "Charities",
            "Cancer Research UK",
            ".png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Diabetes UK")) {
          valActionUpdate(
            "Charities",
            "Diabetes UK",
            "diabetes.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Dignity in Dying")) {
          valActionUpdate(
            "Charities",
            "Dignity in Dying",
            "dignity.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Dogs Trust")) {
          valActionUpdate(
            "Charities",
            "Dogs Trust",
            "dogstrust.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Duke of Edinburgh Awards")) {
          valActionUpdate(
            "Charities",
            "Duke of Edinburgh Awards",
            "duke.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Friends of the Earth")) {
          valActionUpdate(
            "Charities",
            "Friends of the Earth",
            "friendsearth.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (
          data.selectedCharities &&
          data.selectedCharities.includes("Great Ormond Street Hospital")
        ) {
          valActionUpdate(
            "Charities",
            "Great Ormond Street Hospital",
            "ormond.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Kidney Research UK")) {
          valActionUpdate(
            "Charities",
            "Kidney Research UK",
            "kidneyresearch.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("London's Air Ambulance")) {
          valActionUpdate(
            "Charities",
            "London's Air Ambulance",
            "londonambulance.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Marie Curie")) {
          valActionUpdate(
            "Charities",
            "Marie Curie",
            "mariecurie.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Medecins Sans Frontieres")) {
          valActionUpdate(
            "Charities",
            "Medecins Sans Frontieres",
            "medics.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Mind")) {
          valActionUpdate("Charities", "Mind", "mind.png", 7, "1 week", "informCharities");
        }
        if (data.selectedCharities && data.selectedCharities.includes("Movember")) {
          valActionUpdate("Charities", "Movember", "movember.png", 7, "1 week", "informCharities");
        }
        if (data.selectedCharities && data.selectedCharities.includes("National Trust")) {
          valActionUpdate(
            "Charities",
            "National Trust",
            "nationaltrust.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (
          data.selectedCharities &&
          data.selectedCharities.includes("National Trust for Scotland")
        ) {
          valActionUpdate(
            "Charities",
            "National Trust for Scotland",
            "nationaltrustscot.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Oxfam")) {
          valActionUpdate("Charities", "Oxfam", "oxfam.png", 7, "1 week", "informCharities");
        }
        if (data.selectedCharities && data.selectedCharities.includes("PETA")) {
          valActionUpdate("Charities", "PETA", "peta.png", 7, "1 week", "informCharities");
        }
        if (data.selectedCharities && data.selectedCharities.includes("Prostate Cancer UK")) {
          valActionUpdate(
            "Charities",
            "Prostate Cancer UK",
            "prostate.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Samaritans")) {
          valActionUpdate(
            "Charities",
            "Samaritans",
            "samaritans.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("Shelter")) {
          valActionUpdate("Charities", "Shelter", "shelter.png", 7, "1 week", "informCharities");
        }
        if (data.selectedCharities && data.selectedCharities.includes("Sightsavers")) {
          valActionUpdate(
            "Charities",
            "Sightsavers",
            "sightsavers.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("The Blue Cross")) {
          valActionUpdate(
            "Charities",
            "The Blue Cross",
            "bluecross.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("The Children's Society")) {
          valActionUpdate(
            "Charities",
            "The Children's Society",
            "childrensociety.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (
          data.selectedCharities &&
          data.selectedCharities.includes("The Fire Fighters Charity")
        ) {
          valActionUpdate(
            "Charities",
            "The Fire Fighters Charity",
            "firefighters.png",
            7,
            "1 week",
            "informCharities"
          );
        }
        if (data.selectedCharities && data.selectedCharities.includes("WaterAid")) {
          valActionUpdate("Charities", "WaterAid", "wateraid.png", 7, "1 week", "informCharities");
        }
        if (data.selectedCharities && data.selectedCharities.includes("UNICEF")) {
          valActionUpdate("Charities", "UNICEF", "unicef.png", 7, "1 week", "informCharities");
        }
        if (data.selectedCharities && data.selectedCharities.includes("WWF")) {
          valActionUpdate("Charities", "WWF", "wwf.png", 7, "1 week", "informCharities");
        }
        if (data.selectedCharities && data.selectedCharities.includes("SOS Children's Village")) {
          valActionUpdate(
            "Charities",
            "SOS Children's Village",
            "SOS Childrens Village.png",
            7,
            "1 week",
            "informCharities"
          );
        }

        //HMRC
        if (data.informHMRC) {
          valActionUpdate("HMRC", "HMRC", "hmrc_black.png", 28, "4 weeks", "informHMRC", 42);
        }

        //DWP
        if (data.informDWP) {
          valActionUpdate("DWP", "DWP", "DWP.png", 28, "4 weeks", "informDWP", 42);
        }

        //SLC Student Loan Company
        if (data.informStudentFinance) {
          valActionUpdate("SLC", "SLC", "slc.png", 28, "4 weeks", "informStudentFinance");
        }

        //ElectoralRoll
        if (data.informElectoralRoll) {
          valActionUpdate(
            "EROLL",
            "Electoral roll",
            "hmrc_black.png",
            2,
            "2 days",
            "informElectoralRoll"
          );
        }

        //Pension
        if (data.pensionSelection && data.pensionSelection.includes("Lloyds Pension")) {
          valActionUpdate(
            "Pensions",
            "Lloyds Pension",
            "lloyds.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("Your Tomorrow")) {
          valActionUpdate(
            "Pensions",
            "Your Tomorrow",
            "yourtomorrow.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("Canada Life")) {
          valActionUpdate(
            "Pensions",
            "Canada Life",
            "canadalife.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("Scottish Widows")) {
          valActionUpdate(
            "Pensions",
            "Scottish Widows",
            "scottishwidows.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("HBOS")) {
          valActionUpdate("Pensions", "HBOS", "hbos.png", 21, "3 weeks", "informPension", 7);
        }
        if (data.pensionSelection && data.pensionSelection.includes("Equitable Life Pension")) {
          valActionUpdate(
            "Pensions",
            "Equitable Life Pension",
            "equitablelifepension.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }

        if (data.pensionSelection && data.pensionSelection.includes("Aviva")) {
          valActionUpdate("Pensions", "Aviva", "aviva.png", 21, "3 weeks", "informPension", 7);
        }
        if (data.pensionSelection && data.pensionSelection.includes("AJ Bell")) {
          valActionUpdate("Pensions", "AJ Bell", "ajbell.png", 21, "3 weeks", "informPension", 7);
        }
        if (data.pensionSelection && data.pensionSelection.includes("Legal & General")) {
          valActionUpdate(
            "Pensions",
            "Legal & General",
            "legalgeneral.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("Aegon")) {
          valActionUpdate("Pensions", "Aegon", "aegon.png", 21, "3 weeks", "informPension", 7);
        }

        // if (data.pensionSelection && data.pensionSelection.includes("Standard Life")) {
        //   valActionUpdate(
        //     "Pensions",
        //     "Standard Life",
        //     "standardlife.png",
        //     21,
        //     "3 weeks",
        //     "informPension",
        //     7
        //   );
        // }

        // if (
        //   data.pensionSelection &&
        //   data.pensionSelection.includes("Universities Superannuation Scheme")
        // ) {
        //   valActionUpdate(
        //     "Pensions",
        //     "Universities Superannuation Scheme",
        //     "universities.png",
        //     21,
        //     "3 weeks",
        //     "informPension",
        //     7
        //   );
        // }

        if (data.pensionSelection && data.pensionSelection.includes("BT Pension Scheme")) {
          valActionUpdate(
            "Pensions",
            "BT Pension Scheme",
            "btpension.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("RPMI Railpen")) {
          valActionUpdate(
            "Pensions",
            "RPMI Railpen",
            "rpmi.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (
          data.pensionSelection &&
          data.pensionSelection.includes("Royal Bank of Scottland (RBS) Pension fund")
        ) {
          valActionUpdate(
            "Pensions",
            "Royal Bank of Scottland (RBS) Pension fund",
            "bankscottlandpension.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("Barclays pension fund")) {
          valActionUpdate(
            "Pensions",
            "Barclays pension fund",
            "barclayspension.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("Railway Pension Scheme")) {
          valActionUpdate(
            "Pensions",
            "Railway Pension Scheme",
            "rps.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("BP Pension")) {
          valActionUpdate(
            "Pensions",
            "BP Pension",
            "bppension.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (
          data.pensionSelection &&
          data.pensionSelection.includes("Greater Manchester Pension Fund (GMPF)")
        ) {
          valActionUpdate(
            "Pensions",
            "Greater Manchester Pension Fund (GMPF)",
            "gmpf.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (
          data.pensionSelection &&
          data.pensionSelection.includes("National Grid UK Pension Scheme")
        ) {
          valActionUpdate(
            "Pensions",
            "National Grid UK Pension Scheme",
            "nationalgridpension.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("True Potential")) {
          valActionUpdate(
            "Pensions",
            "True Potential",
            "truepotential.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("Interactive Investor")) {
          valActionUpdate(
            "Pensions",
            "Interactive Investor",
            "interactiveinvestor.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (data.pensionSelection && data.pensionSelection.includes("Pension Bee")) {
          valActionUpdate(
            "Pensions",
            "Pension Bee",
            "pensionbee.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }

        if (data.pensionSelection && data.pensionSelection.includes("Teachers' Pensions")) {
          valActionUpdate(
            "Pensions",
            "Teachers' Pensions",
            "Teachers' Pensions.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }
        if (
          data.pensionSelection &&
          data.pensionSelection.includes("Strathclyde pension fund (SPFO)")
        ) {
          valActionUpdate(
            "Pensions",
            "Strathclyde pension fund (SPFO)",
            "strathclyde.png",
            21,
            "3 weeks",
            "informPension",
            7
          );
        }

        // if (data.pensionSelection && data.pensionSelection.includes("USS")) {
        //   valActionUpdate("Pensions", "USS", "uss.png", 21, "3 weeks", "informPension", 7);
        // }

        //Mobile
        if (data.mobileSelection && data.mobileSelection.includes("Asda Mobile")) {
          valActionUpdate(
            "Mobiles",
            "Asda Mobile",
            `mobile/asdamobile.jpg`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("BT Mobile")) {
          valActionUpdate(
            "Mobiles",
            "BT Mobile",
            `mobile/btmobile.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("CMLink")) {
          valActionUpdate("Mobiles", "CMLink", `mobile/cmlilnk.png`, 28, "4 weeks", "informMobile");
        }
        if (data.mobileSelection && data.mobileSelection.includes("Ecotalk")) {
          valActionUpdate(
            "Mobiles",
            "Ecotalk",
            `mobile/ecotalk.jpg`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("EE")) {
          valActionUpdate("Mobiles", "EE", `mobile/ee.png`, 28, "4 weeksinformMobile");
        }
        if (data.mobileSelection && data.mobileSelection.includes("Extreme Connect")) {
          valActionUpdate(
            "Mobiles",
            "Extreme Connect",
            `mobile/truphone.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("giffgaff")) {
          valActionUpdate(
            "Mobiles",
            "giffgaff",
            `mobile/giffgaff.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("iD Mobile")) {
          valActionUpdate(
            "Mobiles",
            "iD Mobile",
            `mobile/idmobile.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Lebara Mobile")) {
          valActionUpdate(
            "Mobiles",
            "Lebara Mobile",
            `mobile/lebara.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Lycamobile")) {
          valActionUpdate(
            "Mobiles",
            "Lycamobile",
            `mobile/lycamobile.jpg`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Now")) {
          valActionUpdate("Mobiles", "Now", `mobile/nowMobile.png`, 28, "4 weeks", "informMobile");
        }
        if (data.mobileSelection && data.mobileSelection.includes("O2")) {
          valActionUpdate(
            "Mobiles",
            "O2",
            `mobile/o2_transparent.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Plusnet Mobile")) {
          valActionUpdate(
            "Mobiles",
            "Plusnet Mobile",
            `mobile/plusNet.jpg`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Sky Mobile")) {
          valActionUpdate(
            "Mobiles",
            "Sky Mobile",
            `mobile/skymobile.jpg`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("SMARTY")) {
          valActionUpdate("Mobiles", "SMARTY", `mobile/smarty.png`, 28, "4 weeks", "informMobile");
        }
        if (data.mobileSelection && data.mobileSelection.includes("Superdrug Mobile")) {
          valActionUpdate(
            "Mobiles",
            "Superdrug Mobile",
            `mobile/superdrugMobile.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Talkmobile")) {
          valActionUpdate(
            "Mobiles",
            "Talkmobile",
            `mobile/talkmobile.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("TalkTalk Mobile")) {
          valActionUpdate(
            "Mobiles",
            "TalkTalk Mobile",
            `mobile/talktalk.jpg`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Tesco Mobile")) {
          valActionUpdate(
            "Mobiles",
            "Tesco Mobile",
            `mobile/tescoMobile.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("The Phone Co-op")) {
          valActionUpdate(
            "Mobiles",
            "The Phone Co-op",
            `mobile/phoneCoop.jpg`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Three")) {
          valActionUpdate("Mobiles", "Three", `mobile/three.png`, 28, "4 weeksinformMobile");
        }
        if (data.mobileSelection && data.mobileSelection.includes("Truphone")) {
          valActionUpdate(
            "Mobiles",
            "Truphone",
            `mobile/truphone.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (
          data.mobileSelection &&
          data.mobileSelection.includes("Utility Warehouse-Telecom Plus")
        ) {
          valActionUpdate(
            "Mobiles",
            "Utility Warehouse-Telecom Plus",
            `mobile/UWTP.jpg`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("Virgin Mobile")) {
          valActionUpdate(
            "Mobiles",
            "Virgin Mobile",
            `mobile/virginmobile.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }
        if (data.mobileSelection && data.mobileSelection.includes("VOXI")) {
          valActionUpdate("Mobiles", "VOXI", `mobile/voxi.png`, 28, "4 weeks", "informMobile");
        }
        if (data.mobileSelection && data.mobileSelection.includes("Vodafone")) {
          valActionUpdate(
            "Mobiles",
            "Vodafone",
            `mobile/vodafone.png`,
            28,
            "4 weeks",
            "informMobile"
          );
        }

        //Dentist
        if (data.informDentist) {
          valActionUpdate(
            "Dentist",
            data["dentistName"],
            `dentist.png`,
            28,
            "4 weeks",
            "informDentist",
            7
          );
        }

        //NHS
        if (data.informNHS) {
          valActionUpdate("NHS", data["gpName"], "nhs.png", 28, "4 weeks", "informNHS", 7);
        }

        //Spam
        if (data.informSpam) {
          if (actionUpdates == null) {
            valActionUpdate("Spam", "Spam", "spam.svg", 2, "2 days", "informSpam");
          } else {
            if (actionUpdates.find((e) => e.action == "DBS"))
              valActionUpdate("DBS", "DBS", "spam.svg", 2, "2 days", "informSpam");

            if (actionUpdates.find((e) => e.action == "TPS"))
              valActionUpdate("TPS", "TPS", "spam.svg", 2, "2 days", "informSpam");
          }
        }

        //Bonds
        if (data.informBonds) {
          valActionUpdate(
            "PremiumBonds",
            "Premium Bonds",
            "bonds.png",
            21,
            "3 weeks",
            "informBonds",
            7
          );
        }

        if (data.essentialRedirection) {
          valActionUpdate(
            "Essetial Redirection",
            "Essetial Redirection",
            "mailredirect.png",
            6,
            "1 week"
          );
        }

        //DVLA
        if (data.informDVLA === true) {
          if (movesData["dvlaQuestions"] && movesData["dvlaQuestions"].dvlaNeed) {
            const { dvlaNeed } = movesData["dvlaQuestions"];
            /* actionUpdates.find(e => e.action =='DVLA-v5c') */
            if (dvlaNeed == "vehicle" || dvlaNeed == "both")
              valActionUpdate(
                "DVLA-v5c",
                "DVLA-v5c",
                "logos/dvla.png",
                14,
                "2 weeks",
                "informDVLA"
              );
            /* || actionUpdates.find(e => e.action =='DVLA-License') */
            if (dvlaNeed == "license" || dvlaNeed == "both")
              valActionUpdate(
                "DVLA-License",
                "DVLA-License",
                "logos/dvla.png",
                14,
                "2 weeks",
                "informDVLA"
              );
          }
        }
        //Banks
        if (this.selectedBanksList && this.selectedBanksList.includes("American Express")) {
          valActionUpdate(
            "Banks",
            "American Express",
            "banks/amex.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Bank of Scotland")) {
          valActionUpdate(
            "Banks",
            "Bank of Scotland",
            "banks/Bank of Scotland.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Barclays Bank")) {
          valActionUpdate(
            "Banks",
            "Barclays Bank",
            "banks/Barclays.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Barclaycard")) {
          valActionUpdate(
            "Banks",
            "Barclaycard",
            "banks/Barclaycard.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Citibank")) {
          valActionUpdate(
            "Banks",
            "Citibank",
            "banks/Citibank.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Clydesdale")) {
          valActionUpdate(
            "Banks",
            "Clydesdale",
            "banks/Clydesdale.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Co-Operative Bank")) {
          valActionUpdate(
            "Banks",
            "Co-Operative Bank",
            "banks/Co-Operative Bank.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("First Direct")) {
          valActionUpdate(
            "Banks",
            "First Direct",
            "banks/First Direct.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Halifax")) {
          valActionUpdate("Banks", "Halifax", "banks/Halifax.png", 21, "3 weeks", "informBanks", 7);
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("HSBC")) {
          valActionUpdate("Banks", "HSBC", "banks/HSBC.png", 21, "3 weeks", "informBanks", 7);
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Investec")) {
          valActionUpdate(
            "Banks",
            "Investec",
            "banks/Investec.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Lloyds")) {
          valActionUpdate(
            "Banks",
            "Lloyds",
            "banks/Lloyds Bank.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Metro Bank")) {
          valActionUpdate(
            "Banks",
            "Metro Bank",
            "banks/Metro Bank.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("MBNA")) {
          valActionUpdate("Banks", "MBNA", "banks/MBNA.png", 21, "3 weeks", "informBanks", 7);
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Monzo")) {
          valActionUpdate("Banks", "Monzo", "banks/Monzo.png", 21, "3 weeks", "informBanks", 7);
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("M&S Bank")) {
          valActionUpdate(
            "Banks",
            "M&S Bank",
            "banks/M&S Bank.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Nationwide")) {
          valActionUpdate(
            "Banks",
            "Nationwide",
            "banks/Nationwide.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Natwest")) {
          valActionUpdate("Banks", "Natwest", "banks/Natwest.png", 21, "3 weeks", "informBanks", 7);
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("RBS")) {
          valActionUpdate(
            "Banks",
            "RBS",
            "banks/Royal Bank of Scotland.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Revolut")) {
          valActionUpdate("Banks", "Revolut", "banks/Revolut.png", 21, "3 weeks", "informBanks", 7);
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Sainsbury’s Bank")) {
          valActionUpdate(
            "Banks",
            "Sainsbury’s Bank",
            "banks/Sainsbury's Bank.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Santander")) {
          valActionUpdate(
            "Banks",
            "Santander",
            "banks/Santander.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Starling")) {
          valActionUpdate(
            "Banks",
            "Starling",
            "banks/Starling.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Tesco Bank")) {
          valActionUpdate(
            "Banks",
            "Tesco Bank",
            "banks/Tesco Bank.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("TSB")) {
          valActionUpdate("Banks", "TSB", "banks/TSB.png", 21, "3 weeks", "informBanks", 7);
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Virgin Money")) {
          valActionUpdate(
            "Banks",
            "Virgin Money",
            "banks/Virgin Money.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }
        if (this.selectedBanksList && this.selectedBanksList.includes("Yorkshire Bank")) {
          valActionUpdate(
            "Banks",
            "Yorkshire Bank",
            "banks/Yorkshire Bank.png",
            21,
            "3 weeks",
            "informBanks",
            7
          );
        }

        //Opticians
        if (data.selectedOpticians && data.selectedOpticians.includes("Vision Express")) {
          valActionUpdate(
            "Opticians",
            "Vision Express",
            "Vision Express.png",
            14,
            "2 weeks",
            "informOpticians"
          );
        }

        if (data.selectedOpticians && data.selectedOpticians.includes("Specsavers")) {
          valActionUpdate(
            "Opticians",
            "Specsavers",
            "Specsavers.png",
            14,
            "2 weeks",
            "informOpticians"
          );
        }

        if (data.selectedOpticians && data.selectedOpticians.includes("Scrivens")) {
          valActionUpdate(
            "Opticians",
            "Scrivens",
            "Scrivens.png",
            14,
            "2 weeks",
            "informOpticians"
          );
        }

        if (data.selectedOpticians && data.selectedOpticians.includes("Optical Express")) {
          valActionUpdate(
            "Opticians",
            "Optical Express",
            "Optical Express.png",
            14,
            "2 weeks",
            "informOpticians"
          );
        }

        if (data.selectedOpticians && data.selectedOpticians.includes("Asda Opticians")) {
          valActionUpdate(
            "Opticians",
            "Asda Opticians",
            "Asda Opticians.png",
            14,
            "2 weeks",
            "informOpticians"
          );
        }

        //Insurances
        let vehicle = [
          { insurer: "AA", icon: "AA.png" },
          { insurer: "Cardif Pinnacle", icon: "Cardif Pinnacle.png" },
          { insurer: "RAC", icon: "RAC.png" },
          { insurer: "Vitality", icon: "Vitality.png" },
          { insurer: "Zurich", icon: "Zurich.png" },
        ];

        let life = [
          { insurer: "AIG", icon: "AIG.png" },
          { insurer: "Beaglestreet", icon: "Beaglestreet.png" },
          { insurer: "BiSL", icon: "BiSL.png" },
          { insurer: "Legal and General", icon: "Legal and General.png" },
        ];

        let pet = [
          { insurer: "Admiral", icon: "Admiral.png" },
          { insurer: "Agriapet", icon: "Agriapet.png" },
          { insurer: "Morethan", icon: "Morethan.png" },
          { insurer: "LV", icon: "LV.png" },
        ];

        let travel = [
          { insurer: "Allianz", icon: "Allianz.png" },
          { insurer: "Post Office", icon: "Post Office.png" },
          { insurer: "Cedar Tree", icon: "Cedar Tree.png" },
          { insurer: "Cover For You", icon: "Cover For You.png" },
          { insurer: "Insurance And Escape", icon: "Insurance And Escape.png" },
        ];

        const allInsurances = [...vehicle, ...life, ...pet, ...travel];

        allInsurances.forEach((insurance) => {
          if (data.informInsurance && data.insurancesSelection.includes(insurance.insurer)) {
            valActionUpdate(
              "Insurances",
              insurance.insurer,
              insurance.icon,
              14,
              "2 weeks",
              "informInsurance"
            );
          }
        });

        // Homecare
        let homecare = [
          { name: "Hometree", icon: "Hometree.png" },
          { name: "HomeServe", icon: "HomeServe.png" },
          { name: "EDF", icon: "EDF.png" },
          { name: "247 Home Rescue", icon: "247 Home Rescue.png" },
        ];

        homecare.forEach((homecareProvider) => {
          if (data.informHomecare && data.selectedHomecare.includes(homecareProvider.name)) {
            valActionUpdate(
              "Homecare",
              homecareProvider.name,
              homecareProvider.icon,
              14,
              "2 weeks",
              "informHomecare"
            );
          }
        });

        // Breakdown
        let breakdownCompanies = [
          { name: "AA", icon: "AA.png" },
          { name: "Cover My Breakdown", icon: "Cover My Breakdown.png" },
          { name: "Emergency Assist", icon: "Emergency Assist.png" },
          { name: "QDOS", icon: "QDOS.png" },
          { name: "RAC", icon: "RAC.png" },
          { name: "Rescue My Car", icon: "Rescue My Car.png" },
        ];

        breakdownCompanies.forEach((breakdownCompany) => {
          if (data.informBreakdown && data.selectedBreakdown.includes(breakdownCompany.name)) {
            valActionUpdate(
              "BreakdownCover",
              breakdownCompany.name,
              breakdownCompany.icon,
              14,
              "2 weeks",
              "informBreakdown",
              7
            );
          }
        });

        //Pets

        let petInsurer = [
          { name: "Admiral", icon: "Admiral.png" },
          { name: "Agriapet", icon: "Agriapet.png" },
          { name: "Morethan", icon: "Morethan.png" },
          { name: "LV", icon: "LV.png" },
        ];

        let petFoods = [
          { name: "Pets at Home", icon: "Pets at Home.png" },
          { name: "Bella & Duke", icon: "Bella & Duke.png" },
          { name: "Lily's Kitchen", icon: "Lily's Kitchen.png" },
          { name: "Tails", icon: "Tails.png" },
          { name: "Butternut Box", icon: "Butternut Box.png" },
          { name: "Harrington's Pet Food", icon: "Harrington's Pet Food.png" },
          { name: "Pure Pet Food", icon: "Pure Pet Food.png" },
          { name: "Different Dog", icon: "Different Dog.png" },
          { name: "Poppy's Picnic", icon: "Poppy's Picnic.png" },
          { name: "Blink! Cats", icon: "Blink! Cats.png" },
          { name: "Naturaw", icon: "Naturaw.png" },
          { name: "Skinner's", icon: "Skinner's.png" },
          { name: "Kasper & Kitty", icon: "Kasper & Kitty.png" },
        ];

        let fleaWormingSubscriptions = [
          { name: "Pets at Home", icon: "Pets at Home.png" },
          { name: "VetBox", icon: "VetBox.png" },
          { name: "ItchPet", icon: "ItchPet.png" },
          { name: "Protect My Pet", icon: "Protect My Pet.png" },
        ];

        let providersForPet = [...petInsurer, ...petFoods, ...fleaWormingSubscriptions];

        let providersSelected = data.pets
          ?.map((pet) => {
            return [
              ...pet["currentInsurer"].map((item) => item.name),
              ...pet["fleaWorming"].map((item) => item.name),
              pet["foodProvider"]?.name,
            ];
          })
          .flat();

        // console.log(providersSelected)

        providersForPet.forEach((providerForPet) => {
          if (data.informPets && providersSelected.includes(providerForPet.name)) {
            valActionUpdate(
              "Pets",
              providerForPet.name,
              providerForPet.icon,
              14,
              "2 weeks",
              "informBreakdown",
              7
            );
          }
        });
      };

      validationServices();

      //we eliminate duplicate services
      let arrayTemp = [];
      this.tableData.forEach((item) => {
        try {
          if (!arrayTemp.some((uniqueItem) => uniqueItem.title === item.title)) {
            arrayTemp.push(item);
          }
        } catch (error) {
          // console.error(error)
        }
      });
      //we eliminate duplicate services

      this.tableData = arrayTemp;

      console.log(this.tableData);

      this.$store.commit("app/setField", ["tableDataServices", this.tableData]);
    },
    toggleDetailsRow(index) {
      if (this.tableData[index].active) {
        this.tableData[index].active = false;
        return;
      }
      this.tableData.forEach((item) => (item.active = false));
      this.tableData[index].active = true;
    },
    setStatusStepper(service,step) {
      if (service.status == 'Action') return ''
      return service['step'] >= step ? 'active': ''
    }
  },
  unmounted() {},
  computed: {
    ...mapState("app", ["user", "currentMove"]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {
    datesDebug(newInfo, oldInfo) {
      if (newInfo != oldInfo) {
        console.log(newInfo);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.text-black {
  color: black;
}

.btn-status {
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  width: 140px;
  text-align: center;
  min-height: 60px;
  max-height: 60px;
  max-width: 160px;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
}

/* Status colors*/

.letter-authority-status {
  background-color: #49d679;
  border-bottom: 4px solid #409b5f;
}

.wait-status {
  background-color: #f77f14;
  border-bottom: 4px solid #bd4d06;
}

.processing-status {
  background-color: #23a9d7;
  border-bottom: 4px solid #2482a3;
}

.update-sent-status {
  background-color: #007e38;
  border-bottom: 4px solid #016b31;
}

.updated-status {
  background-color: #007e38;
  border-bottom: 4px solid #016b31;
}

.pending-action-status {
  background-color: #f66667;
  border-bottom: 4px solid #a82424;
}

/* Status colors*/

.chevron-wait-status {
  color: #f77f14;
}

.chevron-processing-status {
  color: #23a9d7;
}

.chevron-sent-status {
  color: #007e38;
}

.chevron-action-status {
  color: #df3e3e;
}

/*
.chevron-updated-status {
  color: #007e38;
}
*/

/* Status colors*/

.title-change-address {
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 20px;
}

.img-container {
  width: 150px;
  height: 75px;
  position: relative;
  text-align: center;

  img {
    max-width: 137px;
    max-height: 90px;
    border-radius: 20px;
  }
}

.tooltip-status {
  display: none;
}

.points-moves {
  display: flex;
  justify-content: end;

  .dot {
    height: 16px;
    width: 16px;
    background-color: #e1dede;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

  .active {
    background-color: #23a9d7;
  }
}

.container-table {
  background-color: #ffffff;
  border-radius: 25px;
  margin-bottom: 20px;

  .bg-info-light {
    background-color: #eef6ff;
  }
  .content-row {
    display: flex;
    flex-direction: column;
    border-top: 2px solid black;

    .table-item-icon .fa-solid{
      font-size: 20px;
    }
    /*



    .content-row{
      cursor:pointer;
      display: flex;
      padding: 10px 20px;
      flex-direction: row;
      justify-content: space-between;

      .table-item{
        padding: 0 10px;
      }
    }
    */

    .content-row-detail {
      width: 100%;
      max-height: 0;
      transition: all 0.5s ease;
      overflow: hidden;

      .collapse {
        height: auto;
        display: block;

        .collapse-icon [data-toggle="collapse"]:after {
          background-size: 30px;
          filter: brightness(0) invert(0);
        }

        .container-stepper-bar {
          margin-bottom: 100px;
          width: 100%;
          max-width: 340px;

          .step-bar {
            max-width: 340px;
            display: flex;
            justify-content: space-between;
            position: relative;
          }

          .step {
            width: 50px;
            height: 50px;
            position: relative;
          }

          .circle {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #cececf;
            position: relative;
            top: 25%;
            left: 25%;
            z-index: 1;
            .fa-solid {
              margin-left: 3px;
              margin-top: 2px;
              font-size: 19px;
              color: white;
            }
          }

          .step-1.active {
              .circle {
                background: #f77f14;
              }
              .line {
                background: linear-gradient(to right, #f77f14, #38b9ee);
              }
          }

          .step-2.active  {
              .circle {
                background: #38b9ee;
              }
              .line {
                background: linear-gradient(to right, #38b9ee, #288037);
              }
          }

          .step-3.active  {
              .circle {
                background: #288037;
              }
              /*.line {
                background: linear-gradient(to right, #ec0a76, #fee859);
              }*/
          }

          /*.step-4 {
            .circle {
              background: #fee859;
            }
          }*/

          .step-text {
            position: absolute;
            width: 120px;
            text-align: center;
            top: 38px;
            left: -75%;
            color: #000;
            font-size: 13px;

            @media screen and (max-width: 500px) {
              width: 80px;
              left: -35%;
            }
          }

          .line {
            width: 300%;
            height: 5px;
            background: #cececf;
            position: absolute;
            top: 42%;
            left: 25%;

            @media screen and (max-width: 500px) {
              width: 30vw;
              left: 45%;
            }
          }

          .incomplete {
            background: #cececf !important;
          }
        }
      }
    }

    .content-row-detail.active {
      max-height: 100vh;
    }
  }

  /*.content-row:last-child {
    border-radius: 25px;
  }*/

  @media screen and (min-width: 768px) {
    .buttons-items {
      max-width: 190px;
    }
    .company-logo-item {
      max-width: 140px;
    }
  }
}
</style>
