<template>
  <section id="dashboard-ecommerce" class="container-fluid pl-0 pl-md-5">
    <!-- HTML Modals for easy editing -->
    <div style="display: none">
      <WelcomeModal />
    </div>
    <!-- HTML Modals for easy editing -->

    <div
      v-if="displayLabelAboutData"
      class="px-1 text-white text-center animate__animated animate__shakeX information-needed-label mb-2"
      style=""
    >
      <p class="mb-0" style="color: white; font-size: 13px">
        We need the below information to complete your address updates 📍
      </p>
    </div>

    <WizardForm v-if="showFormsWhen(showWizardForm)" />
    <DVLAMiniForm v-if="showFormsWhen(showDVLAMiniForm)" />

    <div v-if="!showWizardForm && !showDVLAMiniForm && currentMove['allDataProvided']">
      <h1 class="mb-1" style="color: #1e1f2d; font-weight: 700 !important">Move Details</h1>

      <MoveDetail v-if="windowWidth < 768" />

      <div class="main-content">

        <MoveDetail v-if="windowWidth >= 768" />

        <div class="text-center px-1">
          <h1 class="mb-0" style="color: #000000; font-weight: 700 !important">
            Address Update progress bar
            <img
              :src="`${this.urlBucket}/dashboard/slot-emoji.png`"
              width="32px"
              style="margin-left: 5px"
              alt=""
            />
          </h1>
        </div>

        <!-- Address Update Progress Bar -->
        <div class="d-flex justify-content-center w-100 mt-3">
          <div class="address-progress-bar">
            <div class="step-bar">
              <div
                :class="`step step-${index + 1}`"
                v-for="(item, index) in [
                  'Sign-up',
                  'Confirm your details',
                  'Answer supplier questions',
                  'Sign letter of authority',
                ]"
                :key="index"
              >
                <div class="circle" :class="{ incomplete: false }">
                  <i class="fa-solid fa-check" v-if="true"></i>
                </div>
                <span class="step-text">{{ item }}</span>
                <div class="line" :class="{ incomplete: false }" v-if="index < 3"></div>
              </div>
            </div>
          </div>
        </div>

        <b-row class="match-height mt-2">
          <!-- Company Table Card -->
          <b-col lg="12">
            <ecommerce-company-table v-if="renderTableServices" />
          </b-col>
          <!--/ Company Table Card -->
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import { mapState, mapGetters } from "vuex";
import axiosCustom from "@axios";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";

import WizardForm from "./WizardForm.vue";
import DVLAMiniForm from "./DVLAMiniForm.vue";
import WelcomeModal from "./modals/WelcomeModal.vue";
import MoveDetail from "./MoveDetail.vue";

export default {
  components: {
    WizardForm,
    DVLAMiniForm,
    BCard,
    BRow,
    BCol,
    EcommerceCompanyTable,
    WelcomeModal,
    MoveDetail,
  },
  data() {
    return {
      data: {},
      user: "",
      windowWidth: 0,
    };
  },
  mounted() {
    const userData = getUserData();
    this.user = userData;
    this.customerConfirmationPopUp();

    // this.moveDetails = this.currentMove.moveDetails;
    let postCode = this.currentMove.moveDetails["moveToPostCode"];

    //Change plan of user if the payment was successful
    const successfulPayment = this.$route.query.successfulPayment;

    if (successfulPayment) {
      let decodedData = window.atob(successfulPayment);
      let email = decodedData.split(":")[0];
      let plan = decodedData.split(":")[1];

      console.log("successfulPayment->", email, plan);

      //Plan Standard = 1
      //Plan King = 2
      if (plan == "standard") plan = 1;
      if (plan == "king") plan = 2;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.accessToken);

      let body = JSON.stringify({ plan, email });

      axiosCustom
        .post("user/utils/changePlan", body)
        .then((response) => response.text())
        .then((success) => {
          console.log(success);
          if (success == "true") {
            this.$store.commit("app/setField", [
              "userPlan",
              {
                plan,
                endDate: this.userPlan["endDate"],
                endDateFormatted: this.userPlan["endDateFormatted"],
              },
            ]);

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Congratulations! 🎉`,
                icon: "StarIcon",
                variant: "success",
                text: `You can now enjoy the benefits of your new membership!`,
              },
            });
          }
        })
        .catch((error) => {
          console.log("error in Change Plan", error);
        });
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    customerConfirmationPopUp() {
      const user = this.user;

      const configSwal = {
        showClass: {
          popup: "animate__animated animate__fadeInDown animate__faster",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOut animate__faster",
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        showCancelButton: false,
      };

      if (!this.lastMove.verifiedDetails) {
        Swal.fire({
          width: 1070,
          html: document.querySelector(".welcome-modal").innerHTML,
          ...configSwal,
        });

        document
          .querySelector(".swal2-container #button-continue")
          .addEventListener("click", () => {
            //Display next step
            document.querySelector(".swal2-container #step-1").style.display = "none";
            document.querySelector(".swal2-container #step-2").style.display = "flex";

            document
              .querySelector(".swal2-container #button-confirm")
              .addEventListener("click", (e) => {
                document.querySelector(".swal2-container #button-confirm").disabled = true;

                document.querySelector(".swal2-container #button-confirm #spinner").style.display =
                  "block";

                axiosCustom
                  .post("api/user/verifyDetails", { email: this.user["email"] })
                  .then((response) => console.log(response))
                  .then((result) => {
                    Swal.close();
                  })
                  .catch((error) => {
                    console.log("error", error);
                    Swal.close();
                  });
              });

            document
              .querySelector(".swal2-container .amend-details")
              .addEventListener("click", () => {
                this.$router.push("/apps/users/edit");
                Swal.close();
              });
          });
      }
    },
    showFormsWhen(formType) {
      return (
        Object.keys(this.currentMove).length > 0 && formType && !this.currentMove["allDataProvided"]
      );
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  computed: {
    ...mapState("app", [
      "urlBucket",
      "tableDataServices",
      "renderTableServices",
      "currentMove",
      "userPlan",
      "lastMove",
      "showWizardForm",
      "showDVLAMiniForm",
      "displayLabelAboutData",
      "allDataProvided",
    ]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {
    // currentMove: function (newValue) {
    //   this.moveDetails = newValue.moveDetails;
    //   let postCode = newValue.moveDetails["moveToPostCode"];
    //   // this.setMarkerMap(postCode)
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

#dashboard-ecommerce {
  .main-content {
    padding-top: 20px;
    background-color: white;
    border-radius: 30px;
    max-width: 970px;
  }

  .address-progress-bar {
    margin-bottom: 100px;
    width: 100%;
    max-width: 500px;

    @media screen and (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .step-bar {
      max-width: 500px;
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    .step {
      width: 50px;
      height: 50px;
      position: relative;
    }

    .circle {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: white;
      position: relative;
      top: 25%;
      left: 25%;
      z-index: 1;
      .fa-solid {
        margin-left: 3px;
        margin-top: 2px;
        font-size: 19px;
        color: white;
      }
    }

    .step-1 {
      .circle {
        background: #33a9d7;
      }
      .line {
        background: linear-gradient(to right, #33a9d7, #1f476f);
      }
    }
    .step-2 {
      .circle {
        background: #1f476f;
      }
      .line {
        background: linear-gradient(to right, #1f476f, #ec0a76);
      }
    }

    .step-3 {
      .circle {
        background: #ec0a76;
      }
      .line {
        background: linear-gradient(to right, #ec0a76, #fee859);
      }
    }

    .step-4 {
      .circle {
        background: #fee859;
      }
    }

    .step-text {
      position: absolute;
      width: 120px;
      text-align: center;
      top: 38px;
      left: -75%;
      color: #000;
      font-size: 13px;

      @media screen and (max-width: 650px) {
        
        width: 80px;
        left: -35%;
      }
    }

    .line {
      width: 300%;
      height: 5px;
      background: blue;
      position: absolute;
      top: 42%;
      left: 25%;

      @media screen and (max-width: 500px) {
        width: 25vw;
      }
    }

    .incomplete {
      background: #cececf !important;
    }
  }
}

.information-needed-label {
  max-width: 535px;
  background-color: #ec0677;
  border-radius: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.z-index {
  z-index: 1;
}

.home p {
  font-size: 15px;
}

.toastification {
  .badge-success {
    background-color: #23a9d7 !important;
  }

  .toastification-title {
    color: #23a9d7 !important;
  }
}

.tooltip-img {
  visibility: hidden;
  /*background-color: white;*/
  border-radius: 6px;
  position: relative;
  z-index: 3;
  top: 59%;
  right: -35%;
  margin-bottom: -190px;
  /*margin-left: -200px;*/

  .visible {
    visibility: visible;
  }

  @media screen and (max-width: 500px) {
    right: 10%;
  }
}
</style>
