var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableData)?_c('div',{staticClass:"col px-0 w-100"},[_c('div',{staticClass:"row d-flex justify-content-between"},[(_vm.moves.length > 1)?_c('div',{staticClass:"col text-right mb-2"},[_c('div',{staticClass:"points-moves"},_vm._l((_vm.moves),function(item,index){return _c('span',{key:index,class:("dot ml-1 " + (_vm.position == index ? 'active' : '')),style:({ 'background-color': _vm.position == index ? _vm.colorPartner : null }),on:{"click":function($event){_vm.position = index;
            _vm.fillInMovingTable();}}})}),0)]):_vm._e()]),(_vm.tableData.length > 0)?_c('div',{staticClass:"container-table animate__animated animate__fadeInUp animate__faster"},_vm._l((_vm.tableData),function(data,index){return _c('div',{key:index,staticClass:"content-row text-black",class:{
        'bg-info-light': index % 2 !== 0,
      }},[_c('div',{staticClass:"row px-1 px-md-4",on:{"click":function($event){return _vm.toggleDetailsRow(index)}}},[_c('div',{staticClass:"col-12 col-md py-1 d-flex justify-content-center align-items-center company-logo-item cursor-pointer"},[_c('div',{staticClass:"img-container"},[_c('b-img',{attrs:{"src":data.icon,"alt":"avatar img"}})],1)]),_c('div',{staticClass:"col-12 col-md d-flex align-items-center justify-content-center cursor-pointer"},[_c('div',[_c('div',{staticClass:"font-weight-normal px-2 pl-sm-3 pr-sm-3",staticStyle:{"font-size":"16px"}},[(data.info.length >= 20)?_c('span',{},[_vm._v(_vm._s(data.info))]):(data.status == 'Waiting')?_c('span',[_vm._v(" We're holding off on sending this update until it's closer to your move date 😊 ")]):(data.status == 'Processing')?_c('span',[_vm._v(" We have sent your update to "+_vm._s(data.title)+" and their operations team will start working on it")]):(data.status == 'Update Sent')?_c('span',[_vm._v(" Nice work, we’ve sent your address update 🙌. "+_vm._s(data.title)+" will now process this ")]):(data.status == 'Action')?_c('span',[_vm._v(" We have been unable to match your details with the DVLA database. Please go to Pending Actions")]):_vm._e()])])]),_c('div',{staticClass:"col-12 py-1 col-md d-flex justify-content-center align-items-center buttons-items cursor-pointer"},[_c('div',{staticClass:"font-weight-bolder btn-status",class:[
              data.status == 'Waiting'
                ? 'wait-status'
                : data.status == 'Processing'
                ? 'processing-status'
                : data.status == 'Update Sent'
                ? 'update-sent-status'
                : data.status == 'Action'
                ? 'pending-action-status'
                : '' ]},[_vm._v(" "+_vm._s(data.status)+" ")]),_c('div',{staticClass:"font-weight-bolder table-item-icon ml-1"},[_c('i',{staticClass:"fa-solid",class:{
                'fa-chevron-up': data.active,
                'fa-chevron-down': !data.active,
                'chevron-wait-status': data.status == 'Waiting',
                'chevron-processing-status': data.status == 'Processing',
                'chevron-sent-status': data.status == 'Update Sent',
                'chevron-action-status': data.status == 'Action',
              }})])])]),_c('div',{staticClass:"content-row-detail px-1 px-md-5",class:{
          active: data.active,
        }},[_c('div',{staticClass:"collapse"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex justify-content-center w-100 mt-3"},[_c('div',{staticClass:"container-stepper-bar"},[_c('div',{staticClass:"step-bar"},_vm._l(([
                      'Waiting to send update',
                      'Processing Update',
                      'Update Sent' ]),function(item,index){return _c('div',{key:index,class:("step step-" + (index + 1) + " " + (_vm.setStatusStepper(data,index + 1)))},[_vm._m(0,true),_c('span',{staticClass:"step-text"},[_vm._v(_vm._s(item))]),(index < 2)?_c('div',{staticClass:"line",style:({'background': (index + 1 == data.step ? '#cececf' : '' )})}):_vm._e()])}),0)])])])])])])}),0):_vm._e()]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle"},[_c('i',{staticClass:"fa-solid fa-check"})])}]

export { render, staticRenderFns }