<template>
    <section class="move-detail row d-flex justify-content-center mb-2">
        <div class="col" style="max-width: 520px">
          <!-- <h1 class="font-weight-bolder mb-0">{{ user.firstName }}´s move</h1> -->
          <b-card
            class="mt-2"
            :style="{ border: colorPartner ? `2px solid ${colorPartner}` : null }"
          >
            <div class="container-icon-shield w-100 text-center">
              <div class="icon px-2">
                <img
                  v-if="currentMove['referrer'] != 'onTheMarket' && isAPartner"
                  :src="`${this.urlBucket}/icons/yellow-shield.png`"
                />
                <img
                  v-else
                  :src="require('@/assets/images/icons/blue-shield.png')"
                  width="60px"
                />
              </div>
            </div>
            <div class="row d-flex d-flex justify-content-between p-1 p-sm-3">
              <div class="col move-desc-left" style="">
                <h2 class="mb-1 moving-from">Moving from</h2>

                <p class="mb-0 text-white">
                  {{ currentMove.moveDetails['moveFromAddress'] }}
                </p>
                <p class="mb-0 text-white">{{ currentMove.moveDetails['city'] }}</p>
                <p class="mb-0 text-white">
                  {{ currentMove.moveDetails['moveFromPostCode'] }}
                </p>
                <p class="mb-0" style="font-weight: 700 !important; color: #decfb9">
                  {{ dateMove(currentMove.moveDetails['moveOutDate']) }}
                </p>
              </div>
              <div class="d-flex align-items-center" style="width: 1%">
                <span class="separator"></span>
              </div>
              <div class="col move-desc-right" style="">
                <h2 class="font-weight-bolder mb-1 moving-to">Moving to</h2>

                <p class="mb-0 text-white">
                  {{ currentMove.moveDetails['moveToAddress'] }}
                </p>
                <p class="mb-0 text-white">{{ currentMove.moveDetails['city'] }}</p>
                <p class="mb-0 text-white">
                  {{ currentMove.moveDetails['moveToPostCode'] }}
                </p>
                <p class="mb-0" style="font-weight: 700 !important; color: #d6c4aa">
                  {{ dateMove(currentMove.moveDetails['moveInDate']) }}
                </p>
              </div>
            </div>
          </b-card>
        </div>
      </section>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";

import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "MoveDetail",
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
    };
  },
  mounted() {
    this.adjustSizeDetail();
  },
  methods: {
    dateMove(dateParam) {
      const date = moment(dateParam).format("Do MMMM YYYY");
      return moment(dateParam).isValid() ? date : "";
    },
    adjustSizeDetail() {
      let resizeObserver = new ResizeObserver(() => {
        var container = document.querySelector(".card");
        var content = document.querySelector(".card-body");

        if (container !== null){
          container.style.height = content.offsetHeight + 10 + "px";
        }
      });

      let div = document.querySelector(".card-body");
      resizeObserver.observe(div);
    },
  },
  computed: {
    ...mapState("app", [
      "urlBucket",
      "currentMove",
    ]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {},
};
</script>

<style lang="scss">
.move-detail {
    h1 {
      color: #fbe759 !important;
      padding-bottom: 20px;
    }

    .container-icon-shield {
      position: absolute;
      left: 0;
      top: -32px;
      display: flex;
      justify-content: center;

      .icon {
        /*background-color: #1e202d;*/
        border-radius: 15px;
      }
    }

    .card {
      /*border: 5px solid #a6c253;*/
      border-radius: 40px;
      /*background-color: #1e202d;*/
      position: relative;
      max-width: 570px;
      height: 234px;
      background: linear-gradient(90deg, #57fc84 0%, #46d085 35%, #329e88 100%);


      .move-desc-left {
        width: 49.5%;
        padding-right: 2rem;
      }

      .move-desc-right {
        width: 49%;
        padding-left: 2rem;
      }

      @media screen and (max-width: 768px) {
        .move-desc-left {
            padding-left: 2rem;

          padding-right: 1rem;
        }

        .move-desc-right {
          padding-left: 1.5rem;
        }
      }
    }

    .card-body {
      padding: 0rem !important;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 40px;
      transform: translate(-50%, -50%);
      width: 98.5%;
      /*max-height: 224px;*/
      min-height: 224px;
      background-color: #1e202d;

      @media screen and (max-width: 440px) {
        min-height: auto;
      }
    }

    .moving-from {
      color: #b2e8fa;
    }

    .moving-to {
      color: #a1e6bf;
    }

    .separator {
      border-left: 2px solid #31343e;
      height: 80px;
    }

    h2,
    p {
      font-weight: 500 !important;
      line-height: 1.8rem;
    }
  }
</style>
