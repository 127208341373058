<template>
  <div class="welcome-modal">
    <div id="step-1" class="row px-1">
      <div class="col-12 col-md-6 p-2 py-md-3 px-md-4 text-left">
        <h1 class="mb-1 pt-1 pt-sm-0" style="font-weight: 700">
          Welcome {{ user["firstName"] }} 👋
        </h1>

        <p class="mb-2 mb-md-3">
          This is your personalised moving dashboard to progress your move and keep track of your
          updates. Here, you can:
        </p>

        <div class="pl-1">
          <p>💡 Provide further information {{ hideText() ? "to suppliers" : "" }}</p>
          <p>📍 Track the progress of updates</p>
          <p>✅ Update your moving details</p>
          <p>🔥 Use hassle-busting tech</p>
        </div>

        <button
          id="button-continue"
          role="button"
          class="mt-2 mt-md-5 button-sloth d-flex justify-content-center"
        >
          <h4 class="mb-0" style="font-weight: 600">CONTINUE</h4>
        </button>
      </div>
      <div class="col-12 col-md-6 px-0">
        <!-- :style="{
          'background-image': 'url(' + `${this.$urlBucketImage}/form/assets/img-modal-1.png` + ')',
        }" -->
        <img :src="`${this.$urlBucketImage}/form/assets/img-modal-1.png`" class="img-welcome" />
      </div>
    </div>

    <div id="step-2" class="row px-1" style="display: none">
      <div class="col-12 col-md-6 p-2 pt-md-3 px-md-4 text-left">
        <p class="mb-2 pt-1 pt-sm-0">
          Please confirm the below information about your move correct
        </p>

        <div class="px-2 py-1 user-details">
          <p style="font-weight: 700">About 🦥</p>
          <p>{{ user["title"] }} {{ user["firstName"] }} {{ user["lastName"] }}</p>
          <p class="mb-2">
            {{
              currentMove.userDetails["contactNumber"]
                ? currentMove.userDetails["contactNumber"]
                : ""
            }}
          </p>

          <p style="font-weight: 700">Moving Out 📍</p>
          <p class="mb-2">
            From: {{ currentMove.moveDetails["moveFromAddress"] }} (on
            {{ dateMove(currentMove.moveDetails["moveOutDate"]) }})
          </p>

          <p style="font-weight: 700">Moving To 🏡</p>
          <p>
            To: {{ currentMove.moveDetails["moveToAddress"] }} (on
            {{ dateMove(currentMove.moveDetails["moveInDate"]) }})
          </p>
        </div>

        <button
          id="button-confirm"
          role="button"
          class="mt-2 mt-md-4 button-sloth d-flex justify-content-center align-items-center"
        >
        <h4 class="mb-0 mr-1" style="font-weight: 600">CONFIRM</h4>
        <div id="spinner" class="spinner-border text-light" role="status" style="display: none;"></div>
        </button>

        <div class="text-center mt-2">
          <p class="amend-details mb-0">AMEND DETAILS</p>
        </div>
      </div>
      <div class="col-12 col-md-6 px-0">
        <!-- :style="{
          'background-image': 'url(' + `${this.$urlBucketImage}/form/assets/img-modal-1.png` + ')',
        }" -->
        <img :src="`${this.$urlBucketImage}/form/assets/img-modal-2.png`" class="img-welcome" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "WelcomeModal",
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {
    // console.log(this.user);
  },
  methods: {
    hideText() {
      return window.innerWidth >= 450;
    },
    dateMove(dateParam) {
      const date = moment(dateParam).format("Do MMMM YYYY");
      return moment(dateParam).isValid() ? date : dateParam;
    },
  },
  computed: {
    ...mapState("app", ["user", "currentMove", "lastMove"]),
  },
};
</script>
<style lang="scss"></style>
