<template>
  <div>
    <div v-if=!fileProcessedOk>
      <div v-if=!fileProcessedERR>
        <p style="font-weight: 800" class="text-left text-white">Upload Back of Driving Licence</p>
        <p class="text-left text-white">Please upload a clear photo of the back of your photocard to automatically fill in your photo Id.</p>
      </div>
      <div style="text-align: left" v-else>
        <p style="font-weight: 800">We could not read your licence 😢</p>
        <p>Here’s how to take a better photo:</p>
        <ul style="color:white">
          <li>Place your licence on a flat surface.</li>
          <li>Ensure good lighting (no shadows or glare).</li>
          <li>Keep the image clear and fully in frame</li>
        </ul>
        <p>You can try again by uploading a new photo or skip this step by
          clicking Continue below to enter your details manually.</p>
      </div>
      <div style="margin-top: 5%"
           class="dropzone"
           @dragover.prevent
           @dragenter.prevent
           @dragstart.prevent
           @drop.prevent="handleFileChange($event.dataTransfer)"
      >
        <input
            id="file-input"
            type="file"
            accept="image/png, image/jpeg"
            @change="handleFileChange($event.target)"
            required
        />
        <h2 v-if="!preview"
            style="margin-top:10px;font-size:20px ;color: black;font-family: Montserrat, Helvetica, Arial, serif;"
            for="file-input"
        >Click here to upload a
          photo</h2>
        <h3 v-if="!preview" style="font-size:18px ;color: black;font-family: Montserrat, Helvetica, Arial, serif;">(<b>Back</b>
          of your licence)</h3>

        <img v-if="preview" style="max-height: 280px;" class="img-prev" v-bind:src="preview"/>
        <!--    <h3 v-if="preview">File name: {{ fileName }}</h3>-->

      </div>
      <div v-if="!fileProcessedOk && !fileProcessedERR" style="width:60%;margin-left: 20%">
        <button v-if="preview"
                role="button"
                id="mainButton"
                class="button-sloth mt-1 d-flex justify-content-center"
                @click="onClickUploadFile()"
                :disabled="sending"
        >
          <h4 v-if="!sending" style="text-transform: none;margin-top: 5px">Read my licence</h4>
          <h4 v-if="sending" style="text-transform: none;margin-top: 5px">Reading details..</h4>
          <div v-if="sending" class="ml-1 spinner-border text-light" role="status">
            <span class="sr-only"></span>
          </div>
        </button>
      </div>
      <div>
        <hr/>
        <p @click="toggleDisplaySecurityIcons" style="font-weight: 800;font-size: 17px" class="text-left text-white">
          About our data safety
          <i v-if="displaySecurityIcons" style="font-size: 18px;margin-left: 0.5rem !important"
             class=" ml-2 fas fa-angle-down"
          ></i>
          <i v-else style="font-size: 18px;margin-left: 0.5rem !important" class=" ml-2 fas fa-angle-up"></i>
        </p>

        <div v-if="displaySecurityIcons" class="features row d-flex justify-content-center">
          <div class="col px-1 text-center" style="color: white; max-width: 105px;">
            <img src="https://drmig4hxr8n3m.cloudfront.net/pre-form/shield.png" width="27px" height="32.5px"
                 class="mb-1 img-fluid" style="max-height: 32.5px;"
            >
            <h6 class="fw-bolder mb-1">SECURE</h6>
            <p class="fw-bolder mb-0" style="font-size: 8px;">SSL ENCRYPTION</p>
          </div>
          <div class="col px-1 text-center" style="color: white; max-width: 105px;">
            <img src="https://drmig4hxr8n3m.cloudfront.net/pre-form/shield-2.png" width="28px" height="32.5px"
                 class="mb-1 img-fluid" style="max-height: 32.5px;"
            >
            <h6 class="fw-bolder mb-1">ICO</h6>
            <p class="fw-bolder mb-0" style="font-size: 8px;">REF: ZA531326</p>
          </div>
          <div class="col px-1 text-center" style="color: white; max-width: 105px;">
            <img src="https://drmig4hxr8n3m.cloudfront.net/pre-form/lock.png" width="25px" height="32.5px"
                 class="mb-1 img-fluid" style="max-height: 32.5px;"
            >
            <h6 class="fw-bolder mb-1">GDPR</h6>
            <p class="fw-bolder mb-0" style="font-size: 8px;">COMPLIANT</p>
          </div>
          <div class="col px-1 text-center" style="color: white; max-width: 105px;">
            <img :src="`${urlBucket}/TaylorRoseSecurity.png`" height="32.5px" class="mb-1 img-fluid"
                 style="width: 100%;margin-bottom: 2.5rem !important; height: 40px"
            >
            <p class="fw-bolder mb-0" style="font-size: 8px;">Top 5 Law Firm</p>
          </div>
        </div>
        <div v-if="displaySecurityIcons"
             style="border-radius:15px; margin-left:10%; width:80%;background-color:#01B65F;margin-top: 1.5rem;"
             class="label-pages d-flex p-2 px-2 align-items-center"
        >
          <p style="padding: 2%;font-size: 14px" class="ms-1 mb-0 fs-xs">
            <strong>Good to know:</strong> <br>Your licence photo is used only
            to read your details and is securely discarded
            immediately after. It’s never saved or stored 🙌
          </p>
        </div>
        <hr/>
        <p class="text-left text-white">Prefer not to? You can skip this step by clicking <b>continue</b> below</p>
      </div>
    </div>
    <div style="margin-top: 6%">
      <div v-if=fileProcessedOk>
        <p style="font-weight: 800">Your licence has been read successfully 🎉</p>
        <p style="margin-left: -3%;">Click continue to progress to confirm your details</p>
      </div>
    </div>

  </div>


</template>
<script>

import axiosCustom from '@/libs/axios'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'ImgUploadBack',
  props: {
    dataModel: {}
  },
  data() {
    return {
      fileName: '',
      preview: null,
      preset: process.env.VUE_APP_UPLOAD_PRESET,
      formData: null,
      cloudName: process.env.VUE_APP_CLOUD_NAME,
      success: '',
      sending: false,
      fileProcessedOk: false,
      fileProcessedERR: false,
      displaySecurityIcons:false
    }
  },
  methods: {
    async onClickUploadFile() {
      this.sending = true

      let formData = new FormData()
      formData.append('file', this.fileBack)
      const token = localStorage.getItem('accessToken')
      try {
        this.loading = true
        let response = await axiosCustom.post(`/user/utils/uploadDVLALicence`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
        })
        console.log(response)

        if (!response.status >= 200 && response.status < 300) {
          console.error(`Server response ${response.status}`)
          return
        }

        this.fileBack = null
        this.$emit('extractedText', response.data)
        if (response.data.photoId !== null) {
          this.fileProcessedOk = true
        } else {
          this.fileProcessedERR = true
        }

      } catch (error) {
        console.error(error)
      }
      this.sending = false
    },
    toggleDisplaySecurityIcons(){
      this.displaySecurityIcons=!this.displaySecurityIcons;
    },
    handleFileChange: function (event) {
      this.fileBack = event.files[0]
      this.fileName = this.fileBack.name
      this.sending=false;
      this.fileProcessedERR=false;
      this.fileProcessedOK=false;
      this.formData = new FormData()

      let reader = new FileReader()
      reader.readAsDataURL(this.fileBack)

      reader.onload = (e) => {
        this.preview = e.target.result
        this.formData.append('file', this.preview)
        console.log(this.formData)
      }
    },
  },
  computed: {
    ...mapState('app', ['baseURL', 'urlBucket', 'user', 'tableDataServices', 'currentMove']),
  },
}
</script>
<style>
.img-prev {
  width: 60%;
}

.dropzone {
  height: fit-content;
  min-height: 200px;
  max-height: 400px;
  width: 80%;
  background: #fdfdfd;
  border-radius: 15px;
  border: 2px dashed #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  width: inherit;
  min-height: 200px;
  max-height: 400px;
  cursor: pointer;
}


</style>
